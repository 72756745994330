
/**
 * Common evenue functions
 */
(function()
{
	var DATA_TYPE = "dataGroup";
	var ADDRESS_BOOK_DATA = "A";
	var PHONE_BOOK_DATA = "P";
	var STORED_CC_DATA = "C";
	var STORED_BA_DATA = "B";
	var TKT_DON_DATA = "T";
	var RLTD_SITES_DATA = "R";
	var HTTP_GET = "GET";
	var HTTP_POST = "POST";
	var PAYMENT_ADD_MODE = "A";
	var PAYMENT_EDIT_MODE = "U";
	var HISTORY_SERVICES_URL = '/app/ws/history/';
	var DONATION_HISTORY_SERVICE_URL = '/donationhistory';
	var ORDER_HISTORY_SERVICE_URL = '/orderhistory';
	var LOAD_MORE_ORDER_HISTORY = '/loadMoreHistory';
	var USER_SERVICE_URL = '/app/ws/user/context';
	var BALLENA_TIMESLOT_URL='/portal/?system=spectra&app=sso&api=timeslot';
	var PATRON_SERVICES_URL = "/app/ws/patron/";
	var ADDITIONAL_PATRON_DATA_SERVICE_URL = "/ptrnAddtnlData";
	var MOBILE_DETECTION_JS_URL = '/app/ws/common/mobiledetectionjs';
	var SOLICITOR_INFO = "/loadSolicitorRegion";
	var m_payment_id = -1;
	var PAYMENT_TYPE_CREDIT_CARD = "CC";
	var PAYMENT_TYPE_BANK_ACCT = "EC";
	var PAYMENT_TYPE_GIFT_CARD = "SV";
	var PAYMODE_CD = "paymodeCd";
	var MODAL_DLG_REGION = "#modal-dialog-region";
	var TRANSFER_DT = "transferDt";
	var TRANSFER_TO = "transferTo";
	var TRANSFER_PAID_BY = "transferFeePaidBy";
	var m_cvvContent;
	
	//evenue cart timer id 
	var m_eVenueTimerId = -1;

	/**
	 *eVenue Constants, for global constants which would be used by other products as well please use et.constants in www/common/script/common.js
	 */
	et.evenue.constants =
	{
		NO : "0",
		YES : "1",
		OPTIONAL : "2",
		PATH_MY_ACCT : "MA", //My Account path
		PATH_TRANSFER : "TR", //Transfers path
		TYPE : "type", //Field name
		ID : "id", //Field name
		ERROR_CD : "errorCd", //Query parameter
		ERROR_MSG : "errorMsg", //Query parameter
		PATRON_URL : '/app/ws/patron/', //Patron service base url
		PAYMENT_SERVICE_URL : "/app/ws/payments/",
		CART_SERVICE_URL : "/app/ws/evenue/cart/",
		BALLENA_CANCEL_CART_URL : "/app/ws/evenue/cancelcart",
		BALLENA_CART_STATUS_URL : "/app/ws/evenue/checkCartStatus",
		CART_URL : "/app/ws/cart/timer",
		CART_UPDATE_URL : "/app/ws/cart/timerUpdate",
		LOGOUT_URL : "/app/ws/authentication/logout",
		LOGIN_URL : "/app/ws/authentication/login",
		QUICK_DONATION_URL : "/fund/drives",
		QUICK_DONATION_DETAILS_URL : "/fund/details",
		QUICK_DONATION_POST_URL : "/fund/donation",
		TB_LABEL_TYPE : "TB",
		TS_LABEL_TYPE : "TS",
		FB_LABEL_TYPE : "FB",
		FS_LABEL_TYPE : "FS",
		PHONE1_LABEL_TYPE : "P1",
		PHONE2_LABEL_TYPE : "P2",
		PHONE3_LABEL_TYPE : "P3",
		PHONE4_LABEL_TYPE : "P4",
		PHONE5_LABEL_TYPE : "P5",
		PHONE6_LABEL_TYPE : "P6",
		MODE_UPDATE : "U",
		CHECKING : "C",
		STORED_VALUE : "SV",
		ECHECK : "EC",
		CREDITCARD : "CC",
		GIFTCARD : "GC",
		OTHER : "OT",
		COUNTRY_US : "US",
		SUCCESS : "success",

		//Additional Patron Data - Data Group Constants
		DATAGRP_PRI_PTRN : "pp",
		DATAGRP_SEC_PTRN : "sp",
		DATAGRP_REL_PTRN : "rp",
		DATAGRP_ATTR : "ar",
		DATAGRP_INTRST : "it",
		DATAGRP_INSTITUTION : "is",
		DATAGRP_PTRN_UDEF : "pu",
		DATAGRP_CUST_UDEF : "cu",
		DATAGRP_DONR_UDEF : "du",
		DATAGRP_TCKT : "tk",
		DATAGRP_DEV : "dv",

		//Char values for the screen type, corresponds to constants of the same name in PatronConstants
		//Used to uniquely identify the correct UDEF to update
		PATRON_UDEF : 80,
		DONOR_UDEF : 68,
		CUSTOMER_UDEF : 67,
		//Constants for Post Sale
		EXCHANGE : "E",
		TRANSFER : "T",
		RETURN : "R",
		REISSUE : "P",
		CANCEL_TRANSFER : "C",
		PRIORITY_PTS : "PP",
		PARENT_ITEM_GROUP : "parent",
		CHILD_ITEM_GROUP : "children",
		GA : "G",
		SECGA : "S",
		CREDIT_CARD_LIST :
		{
			M : "Mastercard",
			V : "Visa",
			A : "American Express",
			C : "Diners Club",
			D : "Discover",
			J : "JCB",
			U : "China Union Pay"
		},
		CREDIT_CARD_IMAGES : {
			V : "fa-cc-visa",
			A : "fa-cc-amex",
			C : "fa-cc-diners-club",
			D : "fa-cc-discover",
			J : "fa-cc-jcb",
			M : "fa-cc-mastercard",
			U : "fa-credit-card-alt"
		},
		BANK_ACCOUNT_LIST : {
			C : "Checking",
			S : "Savings"
		},
		BANK_ACCOUNT_IMAGES : {
			C : "fa-list-alt",
			S : "fa-list-alt"
		},		
		ORDERS : "ORDERS",
		DFLT_SORT_ORDER : "getOrderDt",
		WS_PREFIXES: '/app/rs',
		VERSIONED_WS : '/v1.0',
		FUNDRAISING_WS : '/fundraising',
		CONTROLSETTINGS_WS : '/controlsettings',
		CONSUMER_SPECIAL_EVENT_LIST_STATUS: "CONSUMER_SPECIAL_EVENT_LIST_STATUS",
		CONSUMER_DONATION_PAGE_LIST_DISPLAY : 'CONSUMERDONLISTDISPPAGES',
		TAG_DEFAULT: "DEFAULT",
		TYPE_ORG_CHANNEL: "ORG_CHANNEL",
		CART_REQUEST_TYPE_RENEWAL : "APP_RENEWAL",
		CART_REQUEST_TYPE_RESERVE : "SEAT_RESERVE"
	};

	var C = et.constants, EC = et.evenue.constants;

	et.evenue.evLocaleMap =
	{
		"fr" : "fr_FR",
		"ru" : "ru",
		"cn" : "zh_CN"
	};

	/**
	 * eVenue errors
	 */
	et.evenue.errorCodes =
	{
		PAGE_NOT_AVAILABLE : 20301,
		NO_DONATIONS_EXIST : 20300,
		SITE_ID_REQUIRED : 20436,
		SITE_ID_INVALID : 20437,
		LOGIN_EMAIL_DUPLICATED : 20426,
		PARTIAL_ADDR_NOTALLWD : 20276
	};

	/**
	 * eVenue configuration, could be override in override.js per site.
	 */
	et.evenue.config =
	{
		template : "chocolate",
		hideEmailOptIn : true,
		seatmapUrl : "https://media.paciolan.com/seatmap/v3/script/seatmap.js",
		mobileThreshold : 50, // max line items loaded for a given page on
		desktopThreshold : 500,// mobile or desktop.
		hideReEnterRouting : true,
		hideFinancialName : true,
		useIframe : true,
		showRadioEmailPrefs: false
	};

	/**
	 * Get list items threshold, where threshold is the
	 * maximum amount of list items loaded on a page for each request.
	 */
	et.evenue.getLoadMoreThreshold = function()
	{
		return (et.isMobile() ? et.evenue.config.mobileThreshold : et.evenue.config.desktopThreshold);
	};

	/**
	 * Hide or show 'load more' button depending on whether
	 * there are more items or not.
	 * @param moreItems Boolean returned from server.
	 */
	et.evenue.setLoadMoreBtnVisible = function(moreItems, p_view)
	{
		var btnLoadmore = p_view.find(".btn-loadmore");
		moreItems ? btnLoadmore.show() : btnLoadmore.hide();
	};

	et.evenue.getUserContext = function()
	{
		var deferred = $.Deferred();
		//check user context first from local cache
		var userContext = et.cache.userContext;
		if (!userContext)
		{
			var svr =
			{
				url : USER_SERVICE_URL
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				if (data.status.cd == 0)
				{
					et.cache.userContext = data.value;
					deferred.resolve(data.value);
				}
				else
				{
					deferred.reject();
				}
			}).fail(function()
			{
				deferred.reject();
			});

		}
		else
		{
			deferred.resolve(userContext);
		}

		return deferred.promise();
	};
	
	et.evenue.getMobileDetectionJsUrl = function() {
		var deferred = $.Deferred();

		var svr = {
			url : MOBILE_DETECTION_JS_URL
		};
		
		var jqxhr = et.ajax(svr);
		
		jqxhr.done(function(data) {
			if (data.status.cd == 0) {
				var url = data.value['mobile.detect.js'];
				deferred.resolve(url);
			}
			else {
				deferred.reject();
			}
		})
		.fail(function() {
			deferred.reject();
		});

		return deferred.promise();
	};
	
	/* PAC 21148
	 * called when JSESSIONID cookie is not logged in on checkout flow 
	 */
	et.evenue.redirectToUsi = function(removePacAuthz, continueUrl, userFlow) 
	{	
		//Show USI w/o create acct & activate link
		var usiURL = new URL(window.location.protocol + "//" + document.domain + "/signin");
		
		if (removePacAuthz) {
			$.removeCookie("pac-authz", { path: '/' });
		}
		
		switch (userFlow) {
			case "ST": 
				//student
				usiURL.searchParams.append("ui","ST");
				break;
		}
			
		var redirectURL = ""
		
		if (continueUrl) {
			redirectURL = continueUrl;
		}
		else if (window.location.protocol && window.location.host && window.location.pathname && window.location.hash) 
		{
			redirectURL = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.hash;
		}
		
		if (redirectURL) {
			usiURL.searchParams.append("continue", redirectURL);
		}
		
		window.location = usiURL.toString()
	};
	
	
	et.evenue.getMyAccountv2FeatureFlagZKP = function(){  
          //start ACCT-1285 if sessionStorage is not available call API
		  var usi_siteId = et.getPageParameter(et.constants.SITEID) || et.siteId ;
          var usi_linkId = et.evLinkId || usi_siteId.replace('ev_','');
          if(sessionStorage.getItem("ev_myaccountv2_"+usi_linkId) === null || sessionStorage.getItem("ev_myaccountv2_"+usi_linkId) == ''){
            function makezkpMyAccountv2Request() {
            	var deferred = $.Deferred();
            	var zkpMyAccountV2Enabled  = false;
            	et.ajax({
            	    url: '/pac-api/accounts/myaccountv2',
            	    beforeSend: function(request) {
            	        request.setRequestHeader("siteid", usi_siteId); 
            	    },
            	    timeout:5000,
            	    error:function(){ 
        	    		//return false because API is having issues and it timeout or anything else
      					deferred.resolve(zkpMyAccountV2Enabled);  
            	    }
            	}).done(function(data) { 
            		zkpMyAccountV2Enabled = false;
            		if(typeof data !== "undefined" && typeof data.result !== "undefined" && typeof data.result.myaccountv2 !== "undefined"){
            			sessionStorage.setItem("ev_myaccountv2_"+usi_linkId,  data.result.myaccountv2);
            			
						window.ev_myaccountv2 = {
							status:data.result.myaccountv2
						}

						zkpMyAccountV2Enabled = data.result.myaccountv2 === "true"; 
            		}
  					deferred.resolve(zkpMyAccountV2Enabled); 
    			}).fail(function() {
  					deferred.resolve(zkpMyAccountV2Enabled); 
    			});
            	
            	return deferred.promise();
            }
             return makezkpMyAccountv2Request();
          }else{ 
			var zkpMyAccountV2Enabled = sessionStorage.getItem("ev_myaccountv2_"+usi_linkId) === "true";
        	return zkpMyAccountV2Enabled; 
          }
    };
    
    et.evenue.getUSIFeatureFlags = function(){  
        //start ACCT-1301 Redirect to USI (ACCT-1286 : Ignore redirect on ev14 for EOS server tool) 
        if(window.location.hostname != "ev14.evenue.net" ){
          var usi_siteId = et.getPageParameter(et.constants.SITEID) || et.siteId ;
          var usi_linkId = et.evLinkId || usi_siteId.replace('ev_','');
          var zkpConfigUSI = JSON.parse(sessionStorage.getItem("zkpConfig_"+usi_linkId)) || {};
          //start ACCT-1285 if sessionStorage is not available call API
          if (!zkpConfigUSI || JSON.stringify(zkpConfigUSI) === '{}') {
            function makeRequest() {
            	var deferred = $.Deferred();
            	var usiAccessible  = false;
            	var usiEmbed = false;
				var usiSignoutAccessible = false;
            	var usiFeatureFlags = {"accessible": false, "embed":false,  "signout": false};
            	et.ajax({
            	    url: '/pac-api/catalog/evenueconfig',
            	    beforeSend: function(request) {
            	        request.setRequestHeader("siteId", usi_siteId); 
            	    },
            	    timeout:5000,
            	    error:function(data, status, error){ 
        	    		//return false because API is having issues and it timeout or anything else
      					deferred.resolve(usiFeatureFlags);  
            	    }
            	}).done(function(result) { 
            		zkpConfigUSI = {};
            		if(typeof result !== "undefined"){
            			sessionStorage.setItem('zkpConfig_'+usi_linkId, JSON.stringify(result));
            			zkpConfigUSI = result || {}; 
            		}
            		usiAccessible  = zkpConfigUSI["feature.evenuenext.usi.accessible"] || false;
					usiSignoutAccessible  = zkpConfigUSI["feature.evenuenext.usi.signout.accessible"] || false;
            		usiEmbed  = true; //make this varible false to load legacy log in and ignore USI popup - ACCT-1621
            		usiFeatureFlags = {"accessible": usiAccessible, "embed":usiEmbed, "signout":usiSignoutAccessible};
  					deferred.resolve(usiFeatureFlags); 
    			}).fail(function() {
    				//return false because API is having issues and it failed
  					deferred.resolve(usiFeatureFlags); 
    			});
            	
            	return deferred.promise();
            }
             return makeRequest();
          }else{ 
			var usiAccessible  = zkpConfigUSI["feature.evenuenext.usi.accessible"] || false;
			var usiSignoutAccessible  = zkpConfigUSI["feature.evenuenext.usi.signout.accessible"] || false;
    		var usiEmbed  = true; //make this varible false to load legacy log in and ignore USI popup - ACCT-1621
    		var usiFeatureFlags = {"accessible": usiAccessible, "embed":usiEmbed, "signout":usiSignoutAccessible};
        	return usiFeatureFlags; 
          }
          
        }else{
        	return {"accessible": false, "embed":false};
        } //end ACCT-1301 
    };
	
	/*This function is called from Navbar
	 * Logs user out of site
	 */
	et.evenue.logOut = function(p_linkId, p_goToLogin, clearKmsi, redirectAfterNCLogOut)
	{
		$.when(et.evenue.getMyAccountv2FeatureFlagZKP(), et.evenue.getUSIFeatureFlags()).done(function(myacctv2,usi) { 
			var usiEnabled = usi.accessible && myacctv2 && usi.signout;
			if (usiEnabled) {
				var logOffUrl = window.location.protocol + "//" + window.location.hostname+ '/signout?continue='+ encodeURIComponent(window.location.protocol + "//" + window.location.hostname +'/signin');
				var signInURL = window.location.protocol + "//" + window.location.hostname+ "/signin";
				var redirectURL = "";
				
				if(redirectAfterNCLogOut) {
					redirectURL = signInURL;
				}
				else if (window.location.protocol && window.location.host && window.location.pathname && window.location.search) {
					redirectURL = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.search;
				}
				
				var signOutUrl = window.location.protocol + "//" + window.location.hostname + '/signout?continue='+ encodeURIComponent(window.location.protocol + "//" + window.location.hostname +'/signin');

				//if user context not present then redirect to evenue login
				var pageParams = {
					"linkID" : p_linkId,
					"url" : p_goToLogin ? redirectURL : signOutUrl
				};
				et.evenue.PageController.navigate( !p_goToLogin || redirectAfterNCLogOut ? logOffUrl : signInURL, pageParams, null, null);
				
			} else {

				var isSso = false;
				var ssoTs = localStorage.getItem("ev_sso_" + p_linkId);
				if (ssoTs) {
					var tks = ssoTs.split("::");
					var patronId = et.cache && et.cache.userContext ? et.cache.userContext.patronId : "";
					if (tks && tks.length == 2 && tks[0] == patronId) {
						isSso = true;
					}
				}
				
				var logOffUrl = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/REGLogoff";
				var signInURL = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + p_linkId + "&evm=myac&entry=main.html";
				var redirectURL = "";
		
				if(redirectAfterNCLogOut) {
					redirectURL = signInURL;
				}
				else if (window.location.protocol && window.location.host && window.location.pathname && window.location.hash) {
					redirectURL = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.hash;
				}
				
				var signOutUrl = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + p_linkId + "&evm=regl";
				
				// p_goToLogin is true on session expire
				// therefore if it's false we assume explicit logout
				clearKmsi = clearKmsi || !p_goToLogin;
				$.when(checkKmsiPromise(clearKmsi, et.siteId)).done(function() {
					if (isSso) {
						var sloInit = "et_evenue_logOut";
						window.location = location.protocol + '//'+ location.hostname + (location.port ? ':' + location.port: '') + '/www/' + et.siteId + '/ss/ev/sso/ncredirectout/?siteId=' + et.siteId + '&linkID=' + p_linkId + '&sloInit=' + sloInit + '&toLogin=' + (+(p_goToLogin ? true : false)) + '&url=' + (p_goToLogin ? encodeURIComponent(redirectURL) : encodeURIComponent(signOutUrl));
					} else {
						var svr =
						{
							url : EC.LOGOUT_URL,
							data :
							{
								"siteId" : p_linkId
							},
							type : HTTP_POST
						};
						var jqxhr = et.ajax(svr);
						jqxhr.done(function(data)
						{
							//if user context not present then redirect to evenue login
							var pageParams =
							{
								"linkID" : p_linkId,
								"url" : p_goToLogin ? redirectURL : signOutUrl
							};
							et.evenue.PageController.navigate( !p_goToLogin || redirectAfterNCLogOut ? logOffUrl : signInURL, pageParams, null, null);
						});
					}
				});
			}
		});
	};

	var checkKmsiPromise = function(clearKmsi, siteId) {
		//var deferred = $.Deferred();
		if (!clearKmsi) return true; //deferred.resolve();
		else return et.evenue.removeKmsi(siteId);
	}
	
	et.evenue.removeKmsi = function(_siteId) {
		var deferred = $.Deferred();
		var siteId = _siteId || et.siteId || window.siteID;
		var kmsiFlag = siteId + '_kmsi';
		if (!localStorage.getItem(kmsiFlag)) {
			deferred.resolve();
		} else {
			localStorage.removeItem(kmsiFlag);
			var data = JSON.stringify({
				"siteId": siteId
			});
			var xhr = new XMLHttpRequest();
			xhr.withCredentials = true;
			xhr.open("DELETE", "/pac-api/auth/kmsi");
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.onload = function() { deferred.resolve(); };
			xhr.onerror = function() { deferred.resolve(); };
			xhr.send(data);
		}
		return deferred.promise();
	};
	
	/*This function is called from Navbar
	 * Logs user into site via evenue
	 */
	et.evenue.login = function(p_linkId)
	{
		var signInURL = "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + p_linkId + "&evm=myac&entry=main.html";
		//if user context not present then redirect to evenue login
		et.evenue.PageController.navigate(signInURL, null, null, null);
	};

	et.evenue.updateAdditionalPatronData = function(p_dataGroups, p_ptrnAdditionalDataUiVo)
	{
		var deferred = $.Deferred();

		$.when(et.evenue.getUserContext()).done(function(p_userContext)
		{
			var svr =
			{
				url : PATRON_SERVICES_URL + p_userContext.patronId + ADDITIONAL_PATRON_DATA_SERVICE_URL,
				data :
				{
					dataGroups : p_dataGroups,
					uiVo : p_ptrnAdditionalDataUiVo
				},
				type : HTTP_POST
			}; (JSON.stringify(svr));

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				deferred.resolve(data);
			}).fail(function()
			{
				deferred.reject();
			});
		});

		return deferred.promise();
	};

	/*
	 * Sends updated patron data to evenue
	 */
	et.evenue.updateEvPatron = function(p_patronId, p_pin)
	{
		var deferred = $.Deferred();
		var params =
		{
			"linkID" : et.evLinkId,
			"account" : p_patronId,
			"pin" : p_pin,
			"url" : "https://" + document.domain + "/www/" + et.siteId + "/ss/evenue/success.txt"
		};
		var svr =
		{
			url : "/cgi-bin/ncommerce3/REGActivate",
			data : params,
			type : "GET"
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			deferred.resolve(true);
		}).fail(function()
		{
			deferred.resolve(false);
		});
		return deferred.promise();
	};

	/*
	 * Update new shopping cart timer to evenue
	 */
	et.evenue.evUpdateCartTimer = function(p_expire, p_cartNum)
	{
		var deferred = $.Deferred();
		var params =
		{
			"linkID" : et.evLinkId,
			"cartNum" : p_cartNum,
			"expire" : p_expire,
			"url" : "https://" + document.domain + "/www/" + et.siteId + "/ss/evenue/success.txt"
		};
		var svr =
		{
			url : "/cgi-bin/ncommerce3/SECartRedisplay",
			data : params,
			type : "GET"
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			deferred.resolve(true);
		}).fail(function()
		{
			deferred.resolve(false);
		});
		return deferred.promise();
	};

	/*
	 * Logs user into evenue
	 */
	et.evenue.evLogin = function(p_patronId, p_hash, p_timestamp)
	{
		var deferred = $.Deferred();
		var params =
		{
			"linkID" : et.evLinkId,
			"cn" : p_patronId,
			"pw" : p_hash,
			"ts" : p_timestamp,
			"url" : "https://" + document.domain + "/www/" + et.siteId + "/ss/evenue/success.txt"
		};
		var svr =
		{
			url : "/cgi-bin/ncommerce3/REGLogin",
			data : params,
			type : "GET"
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data === "status:1")
			{
				deferred.resolve(true);
			}
			else if(data === "status:2")
			{
				deferred.resolve(true);
				var pageParams =
				{
					"evm" : 'regu',
					"url" : "https://" + document.domain + "/www/"+et.getPageParameter("siteId")+"/ss/evenue/#QuickDonation?action=" +
					et.getPageParameter("action") + "&siteId=" + et.getPageParameter("siteId") + "&donationCd=" +
					et.getPageParameter("donationCd") + "&locale=" +  "&linkID=" + et.evLinkId
			};
				var redirectURL = "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + et.evLinkId;
				window.location = "https://" + document.domain + et.createUrl(redirectURL, pageParams);
			}
		}).fail(function()
		{
			deferred.resolve(false);
		});
		return deferred.promise();
	};

	/*
	 * Authenticates user in hybrid and returns patronVo
	 */
	et.evenue.userLogin = function(p_values)
	{
		var deferred = $.Deferred();
		var svr =
		{
			url : EC.LOGIN_URL + "?patronId=" + (p_values.patronId ? p_values.patronId : "") + "&email=" + (p_values.email ? p_values.email : "") + "&password=" + p_values.password
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data.status.cd == 0)//eVenue Pac 7.2 login succeeded
			{
				var result = data.value;
				var patron = result.myaccount.patronVo;
				et.cache.myaccount = result.myaccount;

				//Try to log the user into eVenue
				et.evenue.evLogin(patron.patronId, result.hashcode, result.timestamp).done(function(result)
				{
					if (result)//eVenue login succeeded
					{
						$("#hdr_sign_out").show();
						$("#hdr_sign_in").hide();
						if (et.cache.userContext)
						{
							et.cache.userContext.logon = true;
							et.cache.userContext.patronId = patron.patronId;
						}
						deferred.resolve(patron);
					}
					else//eVenue login failed
					{
						deferred.reject();
						et.evenue.userLogout();
					}
				}).fail(function()
				{
					et.evenue.userLogout();
					deferred.reject();
				});

			}
			else//eVenue Pac 7.2 login failed
			{
				et.handleException(data);
				deferred.reject();
			}

		}).fail(function()//eVenue Pac 7.2 login failed
		{
			deferred.reject();
		});
		return deferred.promise();
	};

	/**
	 * Get back a channel based on the channel id (siteId for consumer)
	 */
	et.evenue.getChannel = function(channelId) {
        var deferred = $.Deferred();

        var svr = {
            url : "/app/rs/channels/" + channelId,
        };
        var jqxhr = et.ajax(svr);
        jqxhr.done(function(p_result) {
            if(p_result.status.cd == 0){
                deferred.resolve(p_result.values["channel"]);
            }
            else{
                deferred.reject();
            }
        }).fail(function() {
            deferred.reject();
        });
        return deferred.promise();
    };

    /**
     * Refresh the header login links
     */
    et.evenue.refreshHeaderLoginLinks = function(m_userContext){
        if(m_userContext){
            if (m_userContext.logon) {
                $("#hdr_sign_out").show();
                $("#hdr_sign_in").hide();
                $("#login-widget-logged-container").show();
                $("#login-widget-not-logged-container").hide();
            }
            else {
                $("#hdr_sign_out").hide();
                $("#hdr_sign_in").show();
                $("#login-widget-logged-container").hide();
                $("#login-widget-not-logged-container").show();
                $("#login-widget-welcome-msg").html(""); //Clear welcome message
            }
        }
    };

    /**
     * Set header welcome message
     */
    et.evenue.displayHeaderWelcomeMsg = function(p_accountDetails){
        if(p_accountDetails && p_accountDetails.patronVo){
            $("#login-widget-welcome-msg").html(i18n.lbl_login_widget_welcome_msg.replace("{0}", p_accountDetails.patronVo.resolvedname));
        }
    };

    et.evenue.refreshLoginWidget = function(userContext) {
        if (userContext.logon) {
            $("#hdr_sign_out").show();
            $("#login-widget-logged-container").show();
            $("#login-widget-not-logged-container").hide();
        } else {
            $("#hdr_sign_out").hide();
            $("#login-widget-logged-container").hide();
            $("#login-widget-not-logged-container").show();
        }
    };

    et.evenue.pacSSOLogOut = function() {
        var svr = {
            url : et.evenue.constants.PATRON_URL + "pacSsoLogout",
            type : "GET"
        };
        et.ajax(svr).done(function() {
            et.cache.myaccount = null;
            m_presenter.pageDone({
                forward : et.constants.ERROR_PAGE,
                params : {
                    errorCd : et.evenue.errorCodes.PAGE_NOT_AVAILABLE
                }
            });
        }).fail(function() {
            et.handleFailure();
        });
    };

    et.evenue.resolveSiteId = function () {
        var deferred = $.Deferred();
        var siteId = et.siteId;
        if(siteId){
            et.resolveSiteId(siteId, true)
            .done(
                function (p_siteId, p_sellerId, p_poolId, p_locale, p_locales) {
                    deferred.resolve(p_siteId, p_sellerId, p_poolId);
                })
            .fail(function (p_error, p_result) {
                //deferred.resolve();
                deferred.reject();
                //et.evenue.forwardToErrorPage(p_presenter, {errorCd:p_result.status.cd, errorMsg:p_result.status.mg});
            });
        }

        return deferred.promise();
    };

	/*
	 * Sets patronVo in UserContexVo to null and logon to false
	 * Clears myaccount from cache, sets cached userContext logon to false
	 */
	et.evenue.userLogout = function()
	{
		var svr =
		{
			url : et.evenue.constants.PATRON_URL + "pacSsoLogout"
		};
		et.ajax(svr).done(function()
		{

		});
		et.cache.myaccount = null;
		$("#hdr_sign_out").hide();
		$("#hdr_sign_in").show();
		if (et.cache.userContext)
			et.cache.userContext.logon = false;
	};

	et.evenue.getAdditionalPatronData = function(p_dataGroups)
	{
		var deferred = $.Deferred();

		$.when(et.evenue.getUserContext()).done(function(p_userContext)
		{
			var svr =
			{
				url : PATRON_SERVICES_URL + p_userContext.patronId + ADDITIONAL_PATRON_DATA_SERVICE_URL,
				data :
				{
					dataGroups : p_dataGroups
				},
				type : HTTP_GET
			};

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				deferred.resolve(data);
			}).fail(function()
			{
				deferred.reject();
			});
		});

		return deferred.promise();
	};

	et.evenue.getDonationHistory = function(p_patronId)
	{
		var deferred = $.Deferred();
		var donationhistory = et.cache.donationhistory;

		if (donationhistory)
		{
			deferred.resolve(donationhistory);
		}
		else
		{
			var svr =
			{
				url : HISTORY_SERVICES_URL + p_patronId + DONATION_HISTORY_SERVICE_URL,
				type : HTTP_GET
			};

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				deferred.resolve(data);
				et.cache.donationhistory = data;

			}).fail(function()
			{
				deferred.reject();
			});
		}

		return deferred.promise();
	};

	et.evenue.accountSolicitorInfo = function(org_id, acc_id){
		var deferred = $.Deferred();
		var svr = {
			url : et.evenue.constants.WS_PREFIXES + et.evenue.constants.VERSIONED_WS + et.evenue.constants.FUNDRAISING_WS
			+ "/" + org_id + SOLICITOR_INFO + "?accountId="+acc_id,
			type : HTTP_GET
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data){
			deferred.resolve(data);
			et.cache.solicitorInfo = data;
		}).fail(function(){
			deferred.reject();
		});
		return deferred.promise();
	};

	/**
	 *
	 *
	 * *****************************        Start of Patron Functions     ****************************
	 *
	 *
	 */

	et.evenue.getDefalutAddressSetting = function(p_userContext)
	{
		var config =
		{
			ajax : et.ajax,
			resCountries : et.countries,
			zipcodeLookupRemoteUrl : PATRON_SERVICES_URL + "csz",
			zipcodeLookupCountries : p_userContext.inlAddrSaveto === EC.YES ? ["*"] : ["US", "CA"],
			defaultCountryCd : p_userContext.dfltCountry,
			displayCountry : true,
			displayMailName : false,
			allowPartial : false
		};

		 return config;
	};

	/*
	 * Returns patronVo contained in myAccountVo
	 */

	et.evenue.getPatron = function(p_patronId)
	{
		var deferred = $.Deferred();
		var patron = et.cache.myaccount ? et.cache.myaccount.patronVo : null;
		if (patron)
		{
			deferred.resolve(patron);
		}
		else
		{
			var patronId = p_patronId ? p_patronId : "-1";
			et.evenue.getPatronDetails(patronId).done(function(myaccount)
			{
				deferred.resolve(myaccount.patronVo);
			}).fail(function()
			{
				deferred.reject();
			});
		}
		return deferred.promise();
	};

	et.evenue.getPatronDetails = function(p_patronId)
	{

		var deferred = $.Deferred();
		var myaccount = et.cache.myaccount;
		if (myaccount)
		{
			deferred.resolve(myaccount);
		}
		else
		{
			var patronDtlsUrl = EC.PATRON_URL + ( p_patronId ? p_patronId : "-1") + "/myAccount";
			var svr =
			{

				url : patronDtlsUrl
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				deferred.resolve(data.value);
				et.cache.myaccount = data.value;

			}).fail(function()
			{
				deferred.reject();
			});

		}
		return deferred.promise();
	};
	/**
	 *Method used for collecting key as addressType and values as an object for LabelType(TF,TS,FB,FS) and Label name
	 * This method can be used for validating an addressType. An invalid addressType shouldn't have an entry in this map.
	 * This map can have min of length=1 and max of length =4
	 */
	et.evenue.getAddressLabelMap = function()
	{
		var deferred = $.Deferred();
		et.evenue.getUserContext().done(function(data)
		{
			var labelMap = new Object();
			if (data.billaddrtypedef)
			{
				labelMap[data.billaddrtypedef] =
				{
					"lType" : EC.TB_LABEL_TYPE,
					"label" : data.billaddrtypelbl
				};
			}
			if (data.shipaddrtypdef)
			{
				labelMap[data.shipaddrtypdef] =
				{
					"lType" : EC.TS_LABEL_TYPE,
					"label" : data.shipaddrtypelbl
				};
			}
			if (data.billdonaddrtypedef)
			{
				labelMap[data.billdonaddrtypedef] =
				{
					"lType" : EC.FS_LABEL_TYPE,
					"label" : data.funbilladdrtypelbl
				};
			}
			if (data.shipdonaddrtypedef)
			{
				labelMap[data.shipdonaddrtypedef] =
				{
					"lType" : EC.FS_LABEL_TYPE,
					"label" : data.funshipaddrtypelbl
				};
			}
			deferred.resolve(labelMap);
		});
		return deferred.promise();
	};
	et.evenue.getAddrReqdByType = function(p_userCtx, p_type)
	{

		if (p_type == p_userCtx.billaddrtypedef)
		{
			return p_userCtx.billaddrreq;
		}
		else
		if (p_type == p_userCtx.shipaddrtypdef)
		{
			return p_userCtx.shipaddrreq;
		}
		else
		if (p_type == p_userCtx.billdonaddrtypedef)
		{
			return "1";
		}
		else
		if (p_type == p_userCtx.shipdonaddrtypedef)
		{
			return p_userCtx.donshipaddrreq;
			;
		}

	};
	//
	et.evenue.changeTempAddrTypeMap = function(p_userCtx)
	{

		var changeTypeMap = new Object();
		if (p_userCtx.billaddrtypedef)
		{
			changeTypeMap[p_userCtx.billaddrtypedef] = p_userCtx.tmpbilladdrtype;
		}
		if (p_userCtx.shipaddrtypdef)
		{
			changeTypeMap[p_userCtx.shipaddrtypdef] = p_userCtx.tmpshipaddrtype;
		}
		if (p_userCtx.billdonaddrtypedef)
		{
			changeTypeMap[p_userCtx.billdonaddrtypedef] = p_userCtx.tmpbilldonaddrtype;
		}
		if (p_userCtx.shipdonaddrtypedef)
		{
			changeTypeMap[p_userCtx.shipdonaddrtypedef] = p_userCtx.tmpshipdonaddrtype;
		}
		return changeTypeMap;

	};

	et.evenue.convertAddressLine4ToStateForIntlAddress = function(p_userCtx, p_addressList)
	{
		if (p_userCtx.inlAddrSaveto === EC.YES)
		{
			for (var i = 0; i < p_addressList.length; i++)
			{
				var address = p_addressList[i];
				if ((address.address4 && address.address4 !== "") && (!address.state || address.state === ""))
				{
					address.state = address.address4;
				}
			}
		}
	};

	/**
	 * Method returns map of addressTypes in userContext based on cart type
	 * map will have 1 to 4 entries
	 * key - address type
	 * value - JSON containing attributes: reqd, title, label type, temporary address type
	 * p_cartType - if null all addressTypes from userContext are added to the map
	 */
	et.evenue.getAddressTypeMap = function(p_userContext, p_cartType)
	{
		var index = 0;
		var result =
		{
		};
		if (!p_cartType || p_cartType.indexOf('T') > -1 || (p_cartType == "D" && p_userContext.collectdonaddr == EC.NO))
		{
			result[p_userContext.billaddrtypedef] =
			{
				reqd : p_userContext.billaddrreq,
				title : p_userContext.billaddrtypelbl,
				lbltype : EC.TB_LABEL_TYPE,
				tempType : p_userContext.tmpbilladdrtype,
				seq : index++
			};
			if (p_userContext.collectaddr == EC.OPTIONAL)
			{
				result[p_userContext.shipaddrtypdef] =
				{
					reqd : p_userContext.shipaddrreq,
					title : p_userContext.shipaddrtypelbl,
					lbltype : EC.TS_LABEL_TYPE,
					tempType : p_userContext.tmpshipaddrtype,
					seq : index++
				};
			}
		}
		if (!p_cartType || p_cartType.indexOf('D') > -1)
		{
			if (p_userContext.collectdonaddr != EC.NO)
			{
				result[p_userContext.billdonaddrtypedef] =
				{
					reqd : p_userContext.billdonaddrreq,
					title : p_userContext.funbilladdrtypelbl,
					lbltype : EC.TB_LABEL_TYPE,
					tempType : p_userContext.tmpbilldonaddrtype,
					seq : index++
				};
				if (p_userContext.collectdonaddr == EC.OPTIONAL)
				{
					result[p_userContext.shipdonaddrtypedef] =
					{
						reqd : p_userContext.donshipaddrreq,
						title : p_userContext.funshipaddrtypelbl,
						lbltype : EC.TB_LABEL_TYPE,
						tempType : p_userContext.tmpshipdonaddrtype,
						seq : index++
					};
				}
			}
		}

		return result;

	};

	//gets all Address modules even if they empty. Used in MyAddresses and EditAddress
	et.evenue.getAddressBook = function(p_addresses, p_cartType)
	{
		var deferred = $.Deferred();
		et.evenue.getUserContext().done(function(p_userCtx)
		{
			var addressList = [];
			var counter = 0;

			var tktBillAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userCtx.billaddrtypedef);

			if (!p_cartType || p_cartType == "T" || p_cartType == "TD" || (p_cartType == "D" && p_userCtx.collectdonaddr == EC.NO))
			{
				if (tktBillAddress)
				{
					var address =
					{
					};
					address = $.extend(
					{
					}, tktBillAddress);
					address.reqd = p_userCtx.billaddrreq;
					addressList[counter] = address;

				}
				else
				{
					var address =
					{
					};
					address.title = p_userCtx.billaddrtypelbl;
					address.type = p_userCtx.billaddrtypedef;
					address.lbltype = EC.TB_LABEL_TYPE;
					address.empty = true;
					address.reqd = p_userCtx.billaddrreq;
					addressList[counter] = address;

				}
				counter++;
				if (p_userCtx.collectaddr == EC.OPTIONAL)
				{
					var tktShipAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userCtx.shipaddrtypdef);
					if (tktShipAddress)
					{
						//tktShipAddress.reqd = p_userCtx.shipaddrreq;
						var address =
						{
						};
						address = $.extend(
						{
						}, tktShipAddress);
						address.reqd = p_userCtx.shipaddrreq;
						addressList[counter] = address;
					}
					else
					{
						var address =
						{
						};
						address.title = p_userCtx.shipaddrtypelbl;
						address.type = p_userCtx.shipaddrtypdef;
						address.lbltype = EC.TS_LABEL_TYPE;
						address.empty = true;
						address.reqd = p_userCtx.shipaddrreq;
						addressList[counter] = address;

					}
					counter++;
				}
			}
			if (!p_cartType || p_cartType == "D" || p_cartType == "TD")
			{
				if (p_userCtx.collectdonaddr != EC.NO)
				{
					var funBillAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userCtx.billdonaddrtypedef);
					if (funBillAddress)
					{
						var address =
						{
						};
						address = $.extend(
						{
						}, funBillAddress);
						address.reqd = "1";
						addressList[counter] = address;
					}
					else
					{
						var address =
						{
						};
						address.title = p_userCtx.funbilladdrtypelbl;
						address.type = p_userCtx.billdonaddrtypedef;
						address.lbltype = EC.FB_LABEL_TYPE;
						address.empty = true;
						address.reqd = "1";
						addressList[counter] = address;
					}
					counter++;
					if (p_userCtx.collectdonaddr == EC.OPTIONAL)
					{
						var funShipAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userCtx.shipdonaddrtypedef);
						if (funShipAddress)
						{
							var address =
							{
							};
							address = $.extend(
							{
							}, funShipAddress);
							address.reqd = p_userCtx.donshipaddrreq;
							addressList[counter] = address;

						}
						else
						{
							var address =
							{
							};
							address.reqd = p_userCtx.donshipaddrreq;
							address.title = p_userCtx.funshipaddrtypelbl;
							address.type = p_userCtx.shipdonaddrtypedef;
							address.lbltype = EC.FS_LABEL_TYPE;
							address.empty = true;
							addressList[counter] = address;
						}
						counter++;
					}
				}
			}

			et.evenue.convertAddressLine4ToStateForIntlAddress(p_userCtx, addressList);

			deferred.resolve(addressList);
		});
		return deferred.promise();
	};

	et.evenue.updateAddressTitleType = function(p_userContext, p_address, p_type)
	{
		if (p_type == p_userContext.billaddrtypedef)
		{
			p_address.title = p_userContext.billaddrtypelbl;
			p_address.type = p_userContext.billaddrtypedef;

		}
		else
		if (p_type == p_userContext.shipaddrtypdef)
		{
			p_address.title = p_userContext.shipaddrtypelbl;
			p_address.type = p_userContext.shipaddrtypdef;

		}
		else
		if (p_type == p_userContext.billdonaddrtypedef)
		{
			p_address.title = p_userContext.funbilladdrtypelbl;
			p_address.type = p_userContext.billdonaddrtypedef;

		}
		else
		if (p_type == p_userContext.shipdonaddrtypedef)
		{
			p_address.title = p_userContext.funshipaddrtypelbl;
			p_address.type = p_userContext.shipdonaddrtypedef;

		}
		return p_address;
	};
	/*
	 * Returns list of patron addresses based on list of address types
	 */
	et.evenue.getAddressesByTypes = function(addresses, p_types)
	{
		var returnList = [];
		if (addresses && p_types)
		{
			for (var i = 0; i < addresses.length; i++)
			{
				var address = addresses[i];
				var x = $.inArray(address.type, p_types);
				if (x > -1)
				{
					returnList.push(address);
				}
			}
		}
		return returnList;
	};

	et.evenue.getPhonePropsByType = function(p_userCtx)
	{
		var phoneTypeMap = new Object();

		var prefix = "phtype";
		for (var index = 1; index <= 4; index++)
		{
			updatePhoneTypeMap(phoneTypeMap, p_userCtx, prefix, index);
		}
		prefix = "donphtype";
		for (var index = 1; index <= 2; index++)
		{
			updatePhoneTypeMap(phoneTypeMap, p_userCtx, prefix, index);
		}
		return phoneTypeMap;

	};

	/*
	 * Adds phone type data to p_phoneTypeMap
	 * p_phoneTypeMap - Map of phone types
	 * p_userCtx - userContext
	 * p_prefix - type of phone (phtype, donphtype)
	 * p_index - index of phone type
	 */
	updatePhoneTypeMap = function(p_phoneTypeMap, p_userCtx, p_prefix, p_index)
	{
		var fieldName = p_prefix + p_index + "def";
		if (p_userCtx[fieldName])
		{
			var reqdName = p_prefix + p_index + "req";
			var mobileName = p_prefix + p_index + "mob";
			var tmpType = p_prefix == "phtype" ? "tmpphtype" + p_index : "dontmpphtype" + p_index;
			var lblName = p_prefix + p_index + "lbl";
			var phonePropsObj =
			{
				reqd : p_userCtx[reqdName],
				changeType : p_userCtx[tmpType],
				mobile : p_userCtx[mobileName],
				title : p_userCtx[lblName]
			};
			p_phoneTypeMap[p_userCtx[fieldName]] = phonePropsObj;
		}
	};

	et.evenue.getPhoneBook = function(p_userCtx, p_phones, p_shuffleMobile, p_cartType)
	{
		var phoneList = [];
		var counter = 0;
		var phone;
		var mobileIndex = -1;
		var noDonPhonesConfigured = false;
		noDonPhonesConfigured = !(p_userCtx.donphtype1def || p_userCtx.donphtype2def);

		if (!p_cartType || p_cartType.indexOf("T") != -1 || noDonPhonesConfigured)
		{
			var prefix = "phtype";
			for (var index = 1; index <= 4; index++)
			{
				phone = getPhone(p_phones, prefix, p_userCtx, index);
				if (phone)
				{
					mobileIndex = phone.mobile == 1 ? counter : mobileIndex;
					phoneList[counter] = phone;
					counter++;
				}
			}
		}
		if (!p_cartType || p_cartType.indexOf("D") != -1)
		{
			var prefix = "donphtype";
			for (var index = 1; index <= 2; index++)
			{
				phone = getPhone(p_phones, prefix, p_userCtx, index);
				if (phone)
				{
					mobileIndex = phone.mobile == 1 ? counter : mobileIndex;
					phoneList[counter] = phone;
					counter++;
				}
			}
		}

		if (mobileIndex === -1)
		{
			var mobile = getMobile(p_phones, p_userCtx);
			if (mobile)
			{
				mobileIndex = counter;
				phoneList[counter] = mobile;
				counter++;
			}
		}

		//reshuffle mobile type phone to th elast in the list only if this method is called from Enter Phones
		if (mobileIndex >= 0 && p_shuffleMobile == "1" && p_userCtx.optinreqflg == "1")
		{
			var mobilePhone = phoneList[mobileIndex];
			phoneList.push(mobilePhone);
			phoneList.splice(mobileIndex, 1);
		}
		return phoneList;
	};

	/*
	 * when
	 * R.setup check message opt-in
	 * then return mobile ignore phone type
	 *
	 * the fix is display mobile when opt-in checked
	 */
	getMobile = function(p_phones, p_userCtx)
	{
		if (!p_userCtx.optinreqflg || p_userCtx.optinreqflg !== "1")
		{
			return null;
		}

		var prefix = "phtype";
		for (var index = 1; index <= 4; index++)
		{
			var phone = getPhone(p_phones, prefix, p_userCtx, index);
			if (phone)
			{
				if (phone.mobile == 1)
				{
					return phone;
				}
			}
		}

		var prefix = "donphtype";
		for (var index = 1; index <= 2; index++)
		{
			var phone = getPhone(p_phones, prefix, p_userCtx, index);
			if (phone)
			{
				if (phone.mobile == 1)
				{
					return phone;
				}
			}
		}

		return null;
	};

	/*
	 * Returns phone data for phone type
	 * p_phones - list of PtrnPhoneVos
	 * p_prefix - type of phone (phtype, donphtype)
	 * p_p_userCtx - userContext
	 * p_index - index of phone type
	 */
	getPhone = function(p_phones, p_prefix, p_userCtx, p_index)
	{
		var phone = null;
		var fieldName = p_prefix + p_index + "def";
		if (p_userCtx[fieldName])
		{
			var reqdName = p_prefix + p_index + "req";
			var mobileName = p_prefix + p_index + "mob";
			var lblName = p_prefix + p_index + "lbl";
			var phone = et.getValueFromListByField(p_phones, EC.TYPE, p_userCtx[fieldName]);
			if (!phone)
			{
				phone =
				{
					title : p_userCtx[lblName],
					type : p_userCtx[fieldName],
					empty : true
				};
			}
			phone.reqd = p_userCtx[reqdName];
			phone.mobile = p_userCtx[mobileName];
		}
		return phone;
	};

	/*
	 * Returns address that matches p_fieldValue with value in p_fieldName
	 * if address is not found:
	 * p_returnEmptyAddress == true 'new' address will be returned
	 * else null is returned
	 */
	et.evenue.getAddress = function(p_fieldName, p_fieldValue, p_returnEmptyAddress)
	{
		var address = p_returnEmptyAddress ?
		{
		} : null;
		if (et.cache.myaccount && et.cache.myaccount.patronVo && et.cache.myaccount.patronVo.PtrnAddressVos)
		{
			address = et.getValueFromListByField(et.cache.myaccount.patronVo.PtrnAddressVos, p_fieldName, p_fieldValue);
		}
		return address;
	};

	/*
	 * Adds or updates p_address to list of patron ptrnAddressVos
	 */
	et.evenue.updateAddress = function(p_address)
	{
		et.evenue.getPatronDetails("-1").done(function(accountVo)
		{
			if (accountVo && accountVo.patronVo)
			{
				var patronVo = accountVo.patronVo;
				if (!patronVo.PtrnAddressVos)
				{
					patronVo.PtrnAddressVos = [];
				}
				var existingAddress = patronVo.PtrnAddressVos.length > 0 ? et.getValueFromListByField(patronVo.PtrnAddressVos, EC.TYPE, p_address.type) : null;
				if (existingAddress)
				{
					$.extend(existingAddress, p_address);
				}
				else
				{
					patronVo.PtrnAddressVos.push(p_address);
				}
			}
		});
	};

	/*
	 * Update/Add phones to patronVo
	 * p_phones - list of phones to be updated/added
	 */
	et.evenue.updatePhones = function(p_phones)
	{
		if (p_phones)
		{
			et.evenue.getPatronDetails(-1).done(function(myAccountVo)
			{
				if (myAccountVo && myAccountVo.patronVo)
				{
					var patronVo = myAccountVo.patronVo;
					if (!patronVo.PtrnPhoneVos)
					{
						patronVo.PtrnPhoneVos = [];
						patronVo.PtrnPhoneVos = p_phones;
					}
					else
					{
						var existingPhone;
						$.each(p_phones, function(index, phone)
						{
							existingPhone = et.getValueFromListByField(patronVo.PtrnPhoneVos, EC.TYPE, phone.type);
							if (existingPhone)
							{
								$.extend(existingPhone, phone);
							}
							else
							{
								patronVo.PtrnPhoneVos.push(phone);
							}
						});
					}
					et.cache.myaccount.patronVo.PtrnPhoneVos = patronVo.PtrnPhoneVos;
				}
			});
		}
	};

	/*
	 * Adds or updates a list of p_addresses to list of patron ptrnAddressVos
	 */
	et.evenue.updateAddresses = function(p_addresses)
	{
		var deferred = $.Deferred();
		et.evenue.getUserContext().done(function(p_userContext)
		{
			et.evenue.getPatronDetails(p_userContext.patronId).done(function(p_accountVo)
			{
				if (p_accountVo)
				{
					if (!p_accountVo.patronVo.PtrnAddressVos)
					{
						et.cache.myaccount.patronVo.PtrnAddressVos = [];

					}
					if (p_addresses)
					{
						if (p_addresses.length > 0)
						{
							et.evenue.getAddressLabelMap().done(function(labelMap)
							{
								for (var i = 0; i < p_addresses.length; i++)
								{
									var existingAddress = et.getValueFromListByField(p_accountVo.patronVo.PtrnAddressVos, EC.TYPE, p_addresses[i].type);
									if (existingAddress)
									{
										$.extend(existingAddress, p_addresses[i]);
									}
									else
									{
										var labelValue = labelMap[p_addresses[i].type];
										if (labelValue)
										{
											p_addresses[i].title = labelValue.label;
											et.cache.myaccount.patronVo.PtrnAddressVos.push(p_addresses[i]);
										}
									}
								}
								deferred.resolve(labelMap);
							});
							return deferred.promise();
						}
					}
				}
				deferred.resolve(p_accountVo);
			});
			deferred.resolve(p_userContext);
		});

	};

	et.evenue.useAddressContent = function(p_userContext, p_cartType, p_labelType, p_title)
	{
		var cbVal;
		var cbText;
		if (!p_cartType || p_cartType.indexOf("T") != -1)
		{
			if (p_labelType == EC.TB_LABEL_TYPE)
			{
				cbVal = p_userContext.billaddrtypedef;
				//cbText = i18n.tkt_bill_addr_title;
			}
			else
			if (p_labelType == EC.TS_LABEL_TYPE)
			{
				cbVal = p_userContext.shipaddrtypdef;
				//cbText = i18n.tkt_ship_addr_title;
			}
		}
		if (!p_cartType || p_cartType.indexOf("D") != -1)
		{
			if (p_labelType == EC.FB_LABEL_TYPE)
			{
				cbVal = p_userContext.billdonaddrtypedef;
				//cbText = i18n.fund_bill_addr_title;
			}
			else
			if (p_labelType == EC.FS_LABEL_TYPE)
			{
				cbVal = p_userContext.shipdonaddrtypedef;
				//cbText = i18n.fund_ship_addr_title;
			}
		}
		var checkBoxArr = [cbVal, p_title];
		return checkBoxArr;
	};

	et.evenue.isAllAddressesEmpty = function(p_validAddresses)
	{
		var isAllAddressEmpty = true;
		for (var index = 0; index < p_validAddresses.length; index++)
		{
			if (!p_validAddresses[index].empty)
			{
				isAllAddressEmpty = false;
				break;
				//convertAddressLine4ToStateForIntlAddress
			}
		}
		return isAllAddressEmpty;
	};
	et.evenue.isAllReqdAddressesExist = function(p_validAddresses)
	{
		var isAllAddressFull = true;
		for (var index = 0; index < p_validAddresses.length; index++)
		{
			if (p_validAddresses[index].empty && p_validAddresses[index].reqd == "1")
			{
				isAllAddressFull = false;
				break;
			}
		}
		return isAllAddressFull;
	};

	/*
	 * validate max length and incomplete address for login account
	 * this function will validate the required addresses in userContext
	 */
	et.evenue.validateAddressMetaData = function(p_validAddresses, p_callBack)
	{
		var addressTypes = [];

		for (var index = 0; index < p_validAddresses.length; index++)
		{
			var address = p_validAddresses[index];
			if (address && address.type && !address.empty && address.reqd == "1")
			{
				addressTypes.push(address.type);
			}
		}

		if (addressTypes.length == 0)
		{
			p_callBack();
		}

		var deferred = $.Deferred();
		var PATRON_SERVICE_URL = "/app/ws/patron/-1/address";
		var url = PATRON_SERVICE_URL + "/validateMetaData";
		var svr =
		{
			data : addressTypes,
			url : url,
			type : "POST"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result)
		{
			if (p_result.status.cd === et.evenue.errorCodes.PARTIAL_ADDR_NOTALLWD)
			{
				p_callBack(true);
			}
			else
			if (p_result.status.cd === 0)
			{
				p_callBack();
			}

			deferred.resolve(p_result.value);
		}).fail(function()
		{
			deferred.reject();
		});
		return deferred.promise();
	};

	et.evenue.processForwardForTktAddress = function(p_userContext, p_addresses, p_incompleteAddr)
	{

		var forward;
		var tktBillAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userContext.billaddrtypedef);
		var tktShipAddress;
		if (p_userContext.collectaddr == EC.OPTIONAL && p_userContext.shipaddrtypdef)
		{
			tktShipAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userContext.shipaddrtypdef);
		}
		if (!tktBillAddress && !tktShipAddress)
		{
			if (et.isMobile())
			{

				forward = "editAddress";
				//becoz at least 1 address has content
			}
			else
			{
				forward = "checkout";
			}
		}
		else
		{
			if (!tktBillAddress || (!tktShipAddress && p_userContext.shipaddrreq == "1") || p_incompleteAddr)
			{

				if (et.isMobile())
				{
					forward = "addressbook";
					//becoz at least 1 address has content
				}
				else
				{
					forward = "checkout";
				}
			}
		}

		return forward;

	};

	et.evenue.processForwardForFdAddresses = function(p_userContext, p_addresses, p_incompleteAddr)
	{
		var forward;
		if (p_userContext.collectdonaddr != EC.NO && p_userContext.billdonaddrtypedef)
		{
			var funBillAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userContext.billdonaddrtypedef);
			var funShipAddress;
			if (p_userContext.collectdonaddr == EC.OPTIONAL && p_userContext.shipdonaddrtypedef)
			{
				funShipAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userContext.shipdonaddrtypedef);
			}
			if (!funBillAddress && !funShipAddress)
			{

				if (et.isMobile())
				{

					forward = "editAddress";
					//becoz no address has content
				}
				else
				{
					forward = "checkout";
				}
			}
			else
			{
				if (!funBillAddress || (!funShipAddress && p_userContext.donshipaddrreq == "1") || p_incompleteAddr)
				{
					if (et.isMobile())
					{
						forward = "addressbook";
						//becoz at least 1 address has content
					}
					else
					{
						forward = "checkout";
					}
				}
			}
		}
		else
		{
			forward = et.evenue.processForwardForTktAddress(p_userContext, p_addresses);
		}
		return forward;
	};
	et.evenue.forwardToEditPhones = function(p_userContext, p_phones)
	{
		var forward;
		var params = et.evenue.getWizardParams();
		if (params.cartType == "T")
		{
			return et.evenue.processTicketingPhones(p_userContext, p_phones);
		}
		else
		if (params.cartType == "D")
		{
			if (p_userContext.donphtype1def || p_userContext.donphtype2def)
			{
				return et.evenue.processFundPhones(p_userContext, p_phones);
			}
			else
			{
				return et.evenue.processTicketingPhones(p_userContext, p_phones);
			}
		}
		else
		{
			if (et.evenue.processTicketingPhones(p_userContext, p_phones) || et.evenue.processFundPhones(p_userContext, p_phones))
			{
				return true;
			}
		}
		return false;
	};
	et.evenue.processTicketingPhones = function(p_userContext, p_phones)
	{
		var tktPhone1 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.phtype1def);
		var tktPhone2;
		var tktPhone3;
		var tktPhone4;

		if (p_userContext.phtype2def)
		{
			tktPhone2 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.phtype2def);
		}
		if (p_userContext.phtype3def)
		{
			tktPhone3 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.phtype3def);
		}
		if (p_userContext.phtype4def)
		{
			tktPhone4 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.phtype4def);
		}

		var isMobileEmptyAndRequired = et.evenue.processMobilePhones(p_userContext, p_phones);
		if (isMobileEmptyAndRequired)
		{
			return true;
		}

		if ((!tktPhone1 && p_userContext.phtype1req == "1") || ((!tktPhone2 || tktPhone2 == "") && p_userContext.phtype2req == "1") || ((!tktPhone3 || tktPhone3 == "") && p_userContext.phtype3req == "1") || ((!tktPhone4 || tktPhone4 == "") && p_userContext.phtype4req == "1"))
		{
			return true;
		}
		return false;
	};

	/*
	 * when
	 *
	 * 1. R.setup check message opt-in
	 * 2. mobile is empty and requried
	 *
	 * then return mobile ignore cart type
	 *
	 * the fix is for EvNavigation.js
	 */
	et.evenue.processMobilePhones = function(p_userContext, p_phones)
	{
		var mobile = getMobile(p_phones, p_userContext);
		if (mobile && mobile.empty === true && mobile.reqd == "1")
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	et.evenue.processFundPhones = function(p_userContext, p_phones)
	{
		var fundPhone1;
		var fundPhone2;
		if (p_userContext.donphtype1def)
		{
			fundPhone1 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.donphtype1def);
		}
		if (p_userContext.donphtype2def)
		{
			fundPhone2 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.donphtype2def);
		}

		var isMobileEmptyAndRequired = et.evenue.processMobilePhones(p_userContext, p_phones);
		if (isMobileEmptyAndRequired)
		{
			return true;
		}

		if (((!fundPhone1 || fundPhone1 == "") && p_userContext.donphtype1req == "1") || ((!fundPhone2 || fundPhone2 == "") && p_userContext.donphtype2req == "1"))
		{
			return true;
		}
		return false;
	};

	et.evenue.phoneOptInValChanged = function(p_optInVos, p_origCbVal)
	{
		return (p_optInVos && p_optInVos.length > 0 && p_origCbVal != undefined && p_optInVos[0].optInFl != p_origCbVal);
	};

	/*
	 * Clears patron from cache
	 */
	et.evenue.clearPatron = function()
	{
		if (et.cache.myaccount)
			et.cache.myaccount = null;
	};

	/**
	 *
	 *
	 * *****************************        End of Patron Functions     ****************************
	 *
	 *
	 */

	/**
	 *
	 *
	 * *****************************        Start of Payment Functions     ****************************
	 *
	 *
	 */

	/*
	 * Gets Payment Id
	 */
	et.evenue.getPaymentId = function()
	{
		if (et.cache.payment && et.cache.payment.paymentVos)
		{
			var paymentLength = et.cache.payment.paymentVos.length;
			if (paymentLength > 0)
			{
				//find the least id < 0 and decrement it
				var vos = et.cache.payment.paymentVos;
				var leastId = vos[0].id;
				for(var i = 1; i < vos.length; i++)
				{
				    if(vos[i].id < leastId)
				        leastId = vos[i].id;
				}
				if(leastId < 0)
				    return --leastId;
			}
			else
			{
				return -2;//start at -2 as the default payment is -1
			}
		}
		return -2;
	};

	/*
	 * Adds payment
	 * Returns false if payment cannot be added
	 * Returns true when no cache is present
	 * Returns the id when able to add successfully.
	 */
	et.evenue.addPaymentVo = function(p_vo, p_mode, p_dupCheck)
	{
		p_dupCheck = typeof p_dupCheck !== 'undefined' ? p_dupCheck : true;
		if (!et.cache.payment)
		{
			return true;
		}
		else
		{
			if (!et.cache.payment.paymentVos)
			{
				et.cache.payment.paymentVos = [];
			}
			if (p_dupCheck)
			{
				if (this.isDuplicatePmt(p_vo))
				{
					return false;
				}
			}
			if (p_mode == PAYMENT_ADD_MODE)
			{
				p_vo.id = this.getPaymentId();
				et.cache.payment.paymentVos.push(p_vo);
			}
			else
			if (p_mode == PAYMENT_EDIT_MODE)
			{
				var result = this.updatePaymentById(p_vo.id, p_vo);
				if (!result)
				{
					return false;
				}
			}
		}
		return p_vo.id;
	};

	/*
	 * Updates payment based on payment id
	 */
	et.evenue.updatePaymentById = function(p_id, p_vo)
	{
		if (et.cache.payment)
		{
			var vos = et.cache.payment.paymentVos;
			if (vos)
			{
				for (var i = 0; i < vos.length; i++)
				{
					if (vos[i].id == p_id)
					{
						vos[i] = p_vo;
						return true;
					}
				}
			}
			return false;
		}
		return false;
	};

	/*
	 * Returns payment based on payment id
	 */
	et.evenue.getPaymentVoById = function(p_id)
	{
		if (et.cache.payment)
		{
			var vos = et.cache.payment.paymentVos;
			if (vos)
			{
				for (var i = 0; i < vos.length; i++)
				{
					if (vos[i].id == p_id)
						return vos[i];
				}
			}
			else
			{
				return null;
			}
		}
		else
		{
			return null;
		}
	};

	/*
	 * Deletes payment based on payment id
	 */
	et.evenue.deletePaymentVoById = function(p_id)
	{
		if (et.cache.payment)
		{
			var payments = et.cache.payment.paymentVos;
			if (payments)
			{
				for (var i = 0; i < payments.length; i++)
				{
					if (payments[i].id == p_id)
					{
						payments.splice(i, 1);
						return true;
					}
				}
			}
		}
		return false;
	};

	/*
	 * Deletes payment based on payment type
	 */
	et.evenue.deletePaymentVoByType = function(p_type)
	{
		var retVal = false;
		if (et.cache.payment)
		{
			var payments = et.cache.payment.paymentVos;
			if (payments)
			{
				for (var i = 0; i < payments.length; i++)
				{
					if (payments[i].paymodeType == p_type)
					{
						payments.splice(i, 1);
						retVal = true;
					}
				}
			}
		}
		return retVal;
	};

	et.evenue.isDuplicatePmt = function(p_vo)
	{
		var payments = et.cache.payment ? et.cache.payment.paymentVos : null;
		if (payments)
		{
			var payment, paymentNmbr;
			for (var i = 0; i < payments.length; i++)
			{
				payment = payments[i];
				paymentNmbr = payment.paymentNmbr;
				if (payment.type == p_vo.type)
				{
					if (payment.storeValueId > 0)
					{
						//TODO you may not want to check stored values at all.
						if (et.evenue.getLastFour(paymentNmbr) == et.evenue.getLastFour(p_vo.paymentNmbr))
						{
							return true;
						}
					}
					else
					{
						if (paymentNmbr == p_vo.paymentNmbr)
						{
							if (PAYMENT_TYPE_BANK_ACCT == p_vo.type)
							{
								if (payment.routingNmbr == p_vo.routingNmbr)
									return true;
							}
							return true;
						}
					}
				}
			}
		}
		return false;
	};

	/*
	 * Returns the paymode of type 'EC'
	 */
	et.evenue.getBankAcctPaymode = function()
	{
		var paymodes = et.cache.userContext.paymodes;
		if (paymodes)
		{
			var paymode;
			for (var p = 0; p < paymodes.length; p++)
			{
				paymode = paymodes[p];
				if (paymode.type == PAYMENT_TYPE_BANK_ACCT)
				{
					return paymode;
				}
			}
		}
		return null;
	};

	/*
	 * Checks if credit card is expired
	 */
	et.evenue.isCCExpired = function(p_mth, p_yr)
	{
		var mm = new Date().getMonth() + 1;
		var yyyy = new Date().getFullYear();

		if (p_yr > yyyy)
		{
			return false;
		}
		else
		if (p_yr < yyyy)
		{
			return true;
		}
		else
		if (p_mth < mm)
		{
			return true;
		}
		else
		{
			return false;
		}
	};
	
	et.evenue.isExpired = function(month, year) {
		var date = new Date(year, month - 1, 30);
		var now = new Date();
		if (date < now) {
			return true;
		}
		return false;
	}

	/*
	 * Returns paymode based on p_paymodeCd
	 */
	et.evenue.getPaymode = function(p_paymodeCd)
	{
		var paymode = et.getValueFromListByField(et.cache.userContext.paymodes, PAYMODE_CD, p_paymodeCd);
		return paymode;
	};

	/*
	 * Returns stored card info : Visa XXXX1234
	 * p_showExpirationDate == true: Visa XXXX1234, Exp 7/2013
	 */
	et.evenue.getCreditCardInfo = function(p_storedCreditCard, p_showExpirationDate)
	{
		var cardInfo = "";
		cardInfo += et.evenue.constants.CREDIT_CARD_LIST[p_storedCreditCard.type] + " ";
		cardInfo += "xxxx" + p_storedCreditCard.number;
		cardInfo += ( p_showExpirationDate ? ", Exp " + et.evenue.formatMonth(p_storedCreditCard.expireMonth) + "/" + p_storedCreditCard.expireYear : "");
		return cardInfo;
	};

	/*
	 * when month is a sigle digit, append a 0 in front of month
	 * return month as MM
	 */
	et.evenue.formatMonth = function(p_month)
	{
		var month;
		if (p_month)
		{
			if (p_month < 10)
			{
				month = "0" + p_month;
			}
			else
			{
				month = p_month;
			}
		}

		return month;
	};

	/*
	 * Returns bank account info : Checking Account xxxx4112
	 */
	et.evenue.getBankAccountInfo = function(p_storedBankAccount)
	{
		var cardInfo = "";
		cardInfo = (p_storedBankAccount.accountType == EC.CHECKING) ? i18n.option_checking : i18n.option_savings;
		cardInfo += " xxxx" + p_storedBankAccount.accountNmbr;
		return cardInfo;
	};

	/*
	 * Returns payment card info : Visa XXXX1234
	 * p_showExpirationDate == true: Visa XXXX1234, Exp 7/2013
	 * For bank account : Checking Account xxxx4112
	 */
	et.evenue.getPaymentCardInfo = function(p_payment, p_showExpirationDate)
	{
		var cardInfo = "";
		if (p_payment.paymodeType == EC.ECHECK)
		{
			cardInfo = (p_payment.bankAcctType == EC.CHECKING) ? i18n.option_checking : i18n.option_savings;
			cardInfo += " Account xxxx" + p_payment.paymentNmbr;
		}
		else
		if (p_payment.paymodeType == EC.CREDITCARD)
		{
			var paymode = et.evenue.getPaymode(p_payment.paymodeCd);
			cardInfo += paymode ? et.evenue.constants.CREDIT_CARD_LIST[paymode.ccType] + " " : "";
			cardInfo += "xxxx" + et.evenue.getLastFour(p_payment.paymentNmbr);
			cardInfo += ( p_showExpirationDate ? ", Exp " + et.evenue.formatMonth(p_payment.ccMonth) + "/" + p_payment.ccYear : "");
		}
		else
		if (p_payment.paymodeType == EC.OTHER)
		{
			var paymode = et.evenue.getPaymode(p_payment.paymodeCd);
			cardInfo = "Other (" + paymode.name + ")";
		}
		return cardInfo;
	};

	/*
	 * Displays the Cvv Modal dialog
	 */
	et.evenue.displayCVVModal = function()
	{
		var options =
		{
			title : i18n.lbl_cvn,
			updateContent : true,
			content : getCvvContent()
		};
		et.evenue.displayModalDialog(options);
		return false;
	};

	/*
	 * Return content of Cvv Modal dialog
	 */
	var getCvvContent = function()
	{
		if (!m_cvvContent)
		{
			var ul = $("<ul></ul>").addClass("listview listview-static");
			var li = getLi().append(getDiv().html(i18n.msg_cvn));
			ul.append(li);
			li = getLi().append(getDiv().html(i18n.msg_purpose));
			ul.append(li);
			li = getLi().append(getDiv().html(i18n.msg_card));
			ul.append(li);
			li = getLi().append(getDiv().append($('<img alt="circle" src="~{url.prefix}inf/common/images/ImgCvvMCVisa.gif" width="179" height="101" />')));
			ul.append(li);
			li = getLi().append(getDiv().html(i18n.msg_ae));
			ul.append(li);
			li = getLi().append(getDiv().append($('<img alt="circle" src="~{url.prefix}inf/common/images/ImgCvvAmex.gif" width="179" height="101" />')));
			ul.append(li);
			m_cvvContent = ul;
		}
		return m_cvvContent;
	};

	/**
	 *
	 *
	 * *****************************        End of Payment Functions     ****************************
	 *
	 *
	 */

	/**
	 *
	 *
	 * *****************************        Start of Order History Functions     ****************************
	 *
	 *
	 */

	/*
	 * method to call getOrderHistory restful service and cache results as neededdflt
	 */
	et.evenue.getOrderHistory = function(p_patronId, p_requestType, p_sortOrder)
	{
		var deferred = $.Deferred(), sortOrder = p_sortOrder || et.evenue.constants.DFLT_SORT_ORDER;

		var cache = et.evenue.getOrderHistoryCache(p_requestType);
		if (cache) {

			deferred.resolve(cache);

		} else {

			var svr =
			{
				url : HISTORY_SERVICES_URL + p_patronId + ORDER_HISTORY_SERVICE_URL,
				data :
				{
					requestType : p_requestType,
					maxItems : et.evenue.getLoadMoreThreshold(),
					sortType : sortOrder
				},
				type : HTTP_GET
			};

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				if (data.status.cd == 0)
				{

					et.evenue.setOrderHistoryCache(p_requestType, data);
				}
				deferred.resolve(data);
			}).fail(function()
			{
				deferred.reject();
			});

		}
		return deferred.promise();
	};

	/**
	 * loads more order history onto a given view.
	 * Author: Nathan LaFranchi
	 * @param params Parameters to be sent to the server.
	 * @param p_view View that the line items will be appended to.
	 * @param params.patronId Id of patron to load more items for
	 * @param params.callback Function to be called to populate the flex grid.
	 *
	 */
	et.evenue.loadMoreOrderHistory = function(params, p_view)
	{
		var deferred = $.Deferred();
		var svr =
		{
			url : HISTORY_SERVICES_URL + params.patronId + LOAD_MORE_ORDER_HISTORY,
			data : params,
			type : "GET"
		};

		var jqxhr = et.ajax(svr);

		jqxhr.done(function(data)
		{
			if (data.status.cd == 0)
			{
				// Append additional line items to grid
				params.callback(data.value.lineItemOHVos);
				// Adding new line items to cache.
				var cache = et.evenue.getOrderHistoryCache(params.requestType);
				cache.value.lineItemOHVos.reverse();
				$.each(cache.value.lineItemOHVos, function(index, lineItem){
					data.value.lineItemOHVos.unshift(lineItem);
				});
				et.evenue.setOrderHistoryCache(params.requestType, data);

				et.evenue.setLoadMoreBtnVisible(data.value.moreItems, p_view);
				deferred.resolve(data);
			}
			else
			{
				et.evenue.setLoadMoreBtnVisible(false, p_view);
				et.displayMessagePanel(
				{
					msgs : [data.status.mg],
					element : p_view.find("#error-panel")
				});
				p_view.find(".text-instruction").remove();
				p_view.find(".panel-default").remove();
			}

		}).fail(function()
		{
			et.handleFailure();
		});
		return deferred.promise();
	};

	et.evenue.setOrderHistoryCache = function(p_requestType, p_data)
	{
		if (p_requestType == "ORDERS")
		{
			et.cache.orderHistory = p_data;
		}
		if (p_requestType == "PACKAGES")
		{
			et.cache.packageHistory = p_data;
		}
		if (p_requestType == "TRANSFERS")
		{
			et.cache.transferHistory = p_data;
		}
	};

	et.evenue.getOrderHistoryCache = function(p_requestType)
	{
		if (p_requestType == "ORDERS")
		{
			return et.cache.orderHistory;
		}
		if (p_requestType == "PACKAGES")
		{
			return et.cache.packageHistory;
		}
		if (p_requestType == "TRANSFERS")
		{
			return et.cache.transferHistory;
		}
	};

	/*
	 * Returns select drowp with Order History options
	 */
	et.evenue.getOrderHistorySelect = function()
	{
		var deferred = $.Deferred();
		et.evenue.getOrderHistory(-1, "ORDERS").done(function(orderHistory)
		{
			var select = getSelect(getOrderHistorySelectOptions(orderHistory.value));
			deferred.resolve(select);
		}).fail(function()
		{
			deferred.reject();
		});
		return deferred.promise();
	};

	et.evenue.getOrderHistorySelectOnStart = function(orderHistory)
	{
		var deferred = $.Deferred();
		var select = getSelect(getOrderHistorySelectOptions(orderHistory.value));
		deferred.resolve(orderHistory, select);
		return deferred.promise();
	};
	/*
	 * Creates list of Order History Select options based on flags
	 */
	var getOrderHistorySelectOptions = function(p_orderHistory)
	{
		var options = [];

		if (!p_orderHistory)
			return options;
		options.push(
		{
			id : "orderHistory",
			text : i18n.lbl_order_history
		});

		if (p_orderHistory.viewExchange == EC.YES)
			options.push(
			{
				id : "exchangeHistory",
				text : i18n.opt_exchanges
			});
		if (p_orderHistory.viewPackage == EC.YES)
			options.push(
			{
				id : "packageHistory",
				text : i18n.opt_item_packages
			});
		if (p_orderHistory.vieweVenueTransfer == EC.YES)
			options.push(
			{
				id : "transferHistory",
				text : i18n.opt_transfers
			});
		if (p_orderHistory.viewRenewal == EC.YES)
			options.push(
			{
				id : "renewalHistory",
				text : i18n.opt_renewals
			});
		if (p_orderHistory.viewReturn == EC.YES)
			options.push(
			{
				id : "returnHistory",
				text : i18n.opt_returns
			});
		return options;
	};

	/**
	 *
	 *
	 * *****************************        End of Order History Functions     ****************************
	 *
	 *
	 */

	/*
	 * Split exchange events list into out and in arrays
	 */
	et.evenue.getExchangeOutInEvents = function(p_events)
	{
		var exchangeOutEvents = new Array();
		var exchangeInEvents = new Array();

		$.each(p_events, function(index, eventVo)
		{
			if (eventVo.type == "O")
			{
				exchangeOutEvents.push(eventVo);
			}
			else
			if (eventVo.type == "I")
			{
				exchangeInEvents.push(eventVo);
			}
		});

		var all_events = new Array();
		all_events.push(exchangeOutEvents);
		all_events.push(exchangeInEvents);
		return all_events;
	};

	et.evenue.getSeatBlock = function(p_seat)
	{
		if (!p_seat)
		{
			return "";
		}

		//Bug 52804
		if (!p_seat.row && !p_seat.seats && !p_seat.level)
		{
			return  "";
		}

		//----------Gen Adm if both row and seats are null
		if (p_seat.level === "Gen Adm")
		{
			return i18n.lbl_gen_adm;
		}

		//-----------if level exists but no section, just set level - Bug 53403
		// PAC-11702 && CC#667355 (If level is undefined, hide)
		var seats = p_seat.section ?
			p_seat.level ? p_seat.level + ":" + p_seat.section : p_seat.section
			: p_seat.level;

		//-----------Sectional Gen Adm
		if (!p_seat.row || !p_seat.seats)
		{
			return seats + ": " + i18n.lbl_gen_adm;
		}

		//----------Reserved seats
		// PAC-11702 && CC#667355 (If level is undefined, hide)
		seats = seats !== '' ? seats + ", " + i18n.lbl_row + " " + p_seat.row : i18n.lbl_row + " " + p_seat.row;
		if (p_seat.seats)
		{
			var seatLabel = i18n.lbl_seat;
			if (p_seat.seats.indexOf('-') > -1)
			{
				seatLabel = i18n.lbl_seats;
			}
			seats = seats + ", " + seatLabel + " " + p_seat.seats;
		}
		return seats;
	};

	et.evenue.isTicket = function(p_type)
	{
		return p_type == "C" || p_type == "S";
	};

	et.evenue.isCombo = function(p_type)
	{
		return p_type == "C";
	};

	et.evenue.isNonTicket = function(p_type)
	{
		return p_type != "C" && p_type != "S";
	};

	/*
	 * If all events are in same facility return facility name
	 */
	et.evenue.getFacilityForCombo = function(p_events, p_facilityField)
	{
		var facility = "";
		var event;
		for (var i = 0; i < p_events.length; i++)
		{
			event = p_events[i];
			if (i == 0)
				facility = event[p_facilityField];
			else
			{
				if (facility != event[p_facilityField])
					return "";
			}
		}
		return facility;
	};

	et.evenue.getWizardParams = function()
	{
		var action = et.getPageParameter("action");
		var cartType = et.getPageParameter("cartType");
		var continueUrl = et.getPageParameter("continueUrl");
		var gcUrl = et.getPageParameter("gcUrl");
		var studentType = et.getPageParameter("st");
		var process = et.getPageParameter("process");
		var evm = et.getPageParameter("evm");
		var params =
		{
			"action" : action,
			"cartType" : cartType,
			"continueUrl" : continueUrl,
			"gcUrl" : gcUrl,
			"st" : studentType,
			"process" : process,
			"evm" : evm
		};
		
		var ballena = et.getPageParameter("ballena");
		if(ballena)
			params.ballena = ballena;
		
		var rsdCart = et.getPageParameter("rsdCart");
		if(rsdCart)
			params.rsdCart = rsdCart;
		
		var orderId = et.getPageParameter("orderId");
		if(orderId)
			params.orderId = orderId;
					
		return params;

	};

	/*
	 * Displays ItemInfo region
	 * p_div - id of div where region will be displayed
	 * p_item - json used to populate the region, format below:
	 * 	{
	 * 		logo: url to logo
	 * 		name: item name
	 * 		date: event date
	 * 		facility: item facility
	 * 		season: item season
	 * 		pl: price level
	 * 		pt: price type
	 * 		seats: seats
	 * 	}
	 */
	et.evenue.displayItemInfoRegion = function(p_div, p_item)
	{
		var itemInfoRegion = et.provide(et.evenue.regions.ItemInfo.name);
		itemInfoRegion.go(p_div, function()
		{
			itemInfoRegion.contextChanged(p_item);
		});
	};

	et.evenue.getExchItemInfoRegion = function(p_postSaleEventSeatsVo)
	{
		//Strip away all HTML tags for eventName
		var eventName = $("<div>").html(p_postSaleEventSeatsVo.eventName)[0].innerText || $("<div>").html(p_postSaleEventSeatsVo.eventName)[0].textContent;
		var $container = $("<div/>",
		{
			style : "word-break:break-word;-ms-word-break:break-all;",
			class : "box-b no-padding"
		});
		var boxLogoDiv = $("<div/>", {
			 class : "box-logo"
		});
		var $logo = $("<img/>",
		{
			alt: eventName,
			src: "/app/www/ss/evenue/common/images/na.gif",
			"data-logo" : p_postSaleEventSeatsVo.seasonCd + ":" + p_postSaleEventSeatsVo.eventCd
		}).appendTo(boxLogoDiv);

		boxLogoDiv.appendTo($container);

		var $content = $("<div/>",
		{
			class : "box-content"
		}).appendTo($container);

		$("<label>").text(eventName.trim()).addClass("text-major").appendTo($content);

		if (p_postSaleEventSeatsVo.eventDateTime !== "")
		{
			$("<label/>",
			{
				text : p_postSaleEventSeatsVo.eventDateTime
			}).appendTo($content);
		}

		if (p_postSaleEventSeatsVo.facName && !p_postSaleEventSeatsVo.hideFacilityFl)
		{
			$("<label/>",
			{
				text : p_postSaleEventSeatsVo.facName
			}).appendTo($content);
		}

		return $container;
	};

	/*
	 * Appends item info to html element
	 * p_element - element to which info info is appended
	 * p_data - json used to populate the region, format below:
	 * {
	 * 		itemId: seasonCd_eventId - used for class of logo and id of side element
	 * 		content: [ ]
	 * 		padding: true/false - defaults to true
	 * 		sideElement: img/checkbox - element in asideDiv
	 * }
	 */
	et.evenue.getItemInfoRegion = function(p_element, p_data)
	{
		var dfltData =
		{
			itemId : "",
			content : [],
			padding : true,
			sideElement : null
		};
		var data = p_data ? p_data : dfltData;
		var boxDiv = getDiv().addClass("box-b");
		data.padding ? boxDiv.addClass("no-padding") : null;
		var contentDiv = $("<div style='word-break:break-word;-ms-word-break:break-all'/>").addClass("box-content");
		var label, dataList = data.content;
		$.each(dataList, function(index, dataItem)
		{
			var itemName = $("<div>").html(dataItem)[0].innerText || $("<div>").html(dataItem)[0].textContent;
			//Clear all HTML tags
			if (data.sideElement)
			{
				label = getLabel().text(itemName.trim()).prop("for", data.itemId).addClass(index == 0 ? "text-major" : "");
			}
			else
			{
				label = getDiv().html(itemName.trim()).addClass(index == 0 ? "text-major" : "");
			}
			contentDiv.append(label);
		});
		var asideDiv = getDiv().addClass("box-aside");
		if (data.sideElement)
		{
			asideDiv.append(getSideElement(data.sideElement, data.itemId));
		}

		var eventName = $("<div>").html(data.content[0])[0].innerText || $("<div>").html(data.content[0])[0].textContent;
		//Append the logo if it's the event header, or if it has the showLogo flag = true
		if(p_data.seats != null || p_data.showLogo)
		{
			var boxLogo = getDiv().addClass("box-logo");
			boxLogo.append(getImg().attr("alt", eventName).attr("src", "/app/www/ss/evenue/common/images/na.gif").attr("data-logo", data.itemId));
			boxDiv.append(boxLogo);
		}

		boxDiv.append(contentDiv);
		boxDiv.append(asideDiv);
		p_element.append(boxDiv);
	};

	et.evenue.getItemInfo = function(p_item, p_seatStr)
	{
		var result = [p_item.name];
		if (p_item.eventDtStr)
			result.push(et.evenue.formatEventDatetime(p_item.eventDtStr, p_item.eventDtStrTo, false, false, p_item.timeTba == "0" ? false : true));
		if (p_item.facility)
			result.push(p_item.facility);
		if (p_item.season)
			result.push(p_item.season);
		if (p_item.pl)
			result.push(p_item.pl);
		if (p_item.pt)
		{
			var pts = p_item.pt.split("<br>");
			$.each(pts, function(index, pt)
			{
				result.push(pt);
			});
		}
		if (p_seatStr)
		{
			var seats = p_seatStr.split("<br>");
			$.each(seats, function(index, seatStr)
			{
				result.push(seatStr);
			});
		}
		return result;
	};

	et.evenue.getItemKeyVo = function(p_keyStr)
	{
		var index = p_keyStr.indexOf(":");
		if (index != -1)
		{
			var keyVo =
			{
				"seasonCd" : p_keyStr.substring(0, index),
				"itemCd" : p_keyStr.substring(index + 1)
			};

			return keyVo;

		}

		return null;
	};

	et.evenue.getPostSaleSeatKeyVos = function(p_keyStrs)
	{
		if (p_keyStrs.length === 0)
		{
			return;
		}
		var keyVos = [];
		for (var index = 0; index < p_keyStrs.length; index++)
		{
			var keyStr = p_keyStrs[index];
			if (keyStr !== "")
			{
				keyVos.push(this.getPostSaleSeatKeyVo(keyStr));
			}
		}
		return keyVos;
	};

	/*
	 * Returns itemKeyVo from seatId string
	 */
	et.evenue.getPostSaleSeatKeyVo = function(p_string)
	{
		var keyVo;
		if (p_string)
		{
			var colonIndex = p_string.indexOf(":");
			var orderLineId = p_string.substring(0, colonIndex);

			p_string = p_string.substring(colonIndex + 1);
			colonIndex = p_string.indexOf(":");
			var orderLineItemId = p_string.substring(0, colonIndex);

			p_string = p_string.substring(colonIndex + 1);
			var seatId = p_string;

			keyVo =
			{
				"orderLineId" : orderLineId,
				"orderLineItemId" : orderLineItemId,
				"seatId" : seatId
			};
		}
		return keyVo;
	};

	/*
	 * Returns the transfer fee amount
	 */
	et.evenue.getTransferFeeAmount = function(p_events, p_userContext)
	{
		var seasonList = [];
		$.each(p_events, function(index, item)
		{
			var itemKeyVo = et.evenue.getItemKeyVo(item);
			if (itemKeyVo)
			{
				if (seasonList.indexOf(itemKeyVo.seasonCd) < 0)
					seasonList.push(itemKeyVo.seasonCd);
			}
		});

		var amount = 0;
		if (seasonList.length > 0)
		{
			var postSaleSeasonVos = p_userContext.transferPostSaleSeasonVos;
			$.each(seasonList, function(index, seasonCd)
			{
				var vo = et.getValueFromListByField(postSaleSeasonVos, "seasonCd", seasonCd);
				if (vo)
					amount += vo.amount;
			});
		}
		return amount;
	};

	/*
	 * Returns eventInfo created from PostSaleEventSeatsVo
	 * p_eventInfo: PostSaleEventSeatsVo
	 * p_sideElement: img/checkbox/null - element in asideDiv
	 */
	et.evenue.getEventInfoWithSeasonName = function(p_eventInfo, p_sideElement)
	{
		var itemInfo = et.evenue.getEventInfo(p_eventInfo, p_sideElement);
		itemInfo.content.push(p_eventInfo.seasonName);
		return itemInfo;
	};

	/*
	 * Returns eventInfo created from PostSaleEventSeatsVo
	 * p_eventInfo: PostSaleEventSeatsVo
	 * p_sideElement: img/checkbox/null - element in asideDiv
	 */
	et.evenue.getEventInfo = function(p_eventInfo, p_sideElement)
	{
		var itemInfo =
		{
			itemId : p_eventInfo.seasonCd + ":" + p_eventInfo.eventCd,
			content : [p_eventInfo.eventName, p_eventInfo.eventDateTime, p_eventInfo.hideFacilityFl === true ? "" : p_eventInfo.facName],
			sideElement : p_sideElement
		};
		if (p_eventInfo.postSaleSeatVos && p_eventInfo.postSaleSeatVos.length > 0)
		{
			itemInfo.seats = [];
			$.each(p_eventInfo.postSaleSeatVos, function(index, seat)
			{
				var rowSeat = seat.levelSecDispValue;
				if (seat.seatingType == et.evenue.constants.GA)
				{
					rowSeat = i18n.lbl_gen_adm;
				}
				else if (seat.seatingType == et.evenue.constants.SECGA)
				{
					rowSeat += ": " + i18n.lbl_gen_adm;
				}
				else
				{
					if (seat.rowCd)
						rowSeat += ", " + i18n.lbl_row + " " + seat.rowCd;
					if (seat.seatCd)
						rowSeat += ", " + i18n.lbl_seat + " " + seat.seatCd;
				}
				var content = [(rowSeat), (seat.priceLevelName + ", " + seat.priceTypeName)];

				if (seat.barcode && (seat.seatingType == et.evenue.constants.GA || seat.seatingType == et.evenue.constants.SECGA))
				{
					content.push("Barcode " + seat.barcode);
					//content.push(seat.barcode);
				}
				if (seat.price && seat.price >= 0)
				{
					content.push((et.evenue.getFormattedNumber(seat.price, true)));
				}
				itemInfo.seats.push(
				{
					itemId : seat.orderLineId + ":" + seat.orderLineItemId + ":" + seat.seatId,
					content : content,
					sideElement : "checkbox"
				});
			});
		}
		else
		{
			// These are misc items.  They have no PostSaleSeatVos
			itemInfo.seats = [];
			if (p_eventInfo.barcodes)
			{
				var barcodes = p_eventInfo.barcodes.split("^");
				$.each(barcodes, function(index, barcode)
				{
					var content = [("BARCODE  " + barcode)];
					itemInfo.seats.push(
					{
						itemId : barcode,
						content : content,
						sideElement : "checkbox"
					});
				});
			}
		}
		return itemInfo;
	};

	/*
	 * Returns seats details for previously selected Post Sale events
	 */
	et.evenue.getPostSaleSeatDetails = function(p_processId, p_patronId, p_keyVos)
	{
		var deferred = $.Deferred();
		var ORDER_SERVICE_URL = PATRON_SERVICES_URL + p_patronId + "/order";
		var seatDetailsUrl = ORDER_SERVICE_URL + "/seatdetails" + "?type=" + p_processId;

		var svr =
		{
			url : seatDetailsUrl,
			data : p_keyVos,
			type : "POST"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			deferred.resolve(data);
		}).fail(function()
		{
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Returns PostSaleVo for specified processId and updates cache
	 */
	et.evenue.getPostSaleDetails = function(p_patronId, p_processId)
	{
		var deferred = $.Deferred();
		var postSaleDetails = et.cache.postSaleDetails;
		if (postSaleDetails)
		{
			deferred.resolve(postSaleDetails);
		}
		else
		{
			var ORDER_SERVICE_URL = PATRON_SERVICES_URL + p_patronId + "/order/postsale?type=" + p_processId;

			var svr =
			{
				url : ORDER_SERVICE_URL
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				if (data.status.cd == 0)
				{
					et.cache.postSaleDetails = data.value;
					deferred.resolve(data.value);
				}
				else
				{
					deferred.reject();
				}
			}).fail(function()
			{
				deferred.reject();
			});
		}
		return deferred.promise();
	};

	/*
	 * Posts PostSaleVo to the /postsale service and updates the cache
	 */
	et.evenue.setPostSaleDetails = function(p_patronId, p_postSaleVo)
	{
		var deferred = $.Deferred();
		var ORDER_SERVICE_URL = PATRON_SERVICES_URL + p_patronId + "/order/postsale";
		var svr =
		{
			url : ORDER_SERVICE_URL,
			data : p_postSaleVo,
			type : 'POST'
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (et.handleException(data))
			{
				et.cache.postSaleDetails = data.value;
				deferred.resolve(data.value);
			}
			else
			{
				deferred.reject();
			}
		}).fail(function(data)
		{
			et.handleFailure(data);
		});
		return deferred.promise();
	};

	/*
	 * Confirms post sale transaction and updates the cache
	 */
	et.evenue.confirmPostSale = function(p_patronId, p_postSaleVo)
	{
		var deferred = $.Deferred();
		var ORDER_SERVICE_URL = PATRON_SERVICES_URL + p_patronId + "/order/postsaleorder";
		var svr =
		{
			url : ORDER_SERVICE_URL,
			data : p_postSaleVo,
			type : 'POST'
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (et.handleException(data))
			{
				et.cache.postSaleDetails = data.value;
				deferred.resolve(data.value);
			}
			else
			{
				deferred.reject();
			}
		}).fail(function(data)
		{
			et.handleFailure(data);
		});
		return deferred.promise();
	};

	et.evenue.exchangeCartAjax = function(p_cancelExchange, p_callBack)
	{
		var deferred = $.Deferred();
		var EXCHANGE_SERVICE_URL = "/app/ws/exchange/-1";
		var exchangeCartUrl = EXCHANGE_SERVICE_URL + "/exchangeCart";
		var svr =
		{
			url : exchangeCartUrl,
			type : "GET"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result)
		{
			et.handleException(p_result);
			deferred.resolve(p_result.value);
		}).fail(function(p_result)
		{
			et.handleFailure(p_result);
			deferred.reject();
		});
		return deferred.promise();
	};

	et.evenue.cancelExchangeAjax = function(p_cancelExchange)
	{
		var deferred = $.Deferred();
		var EXCHANGE_SERVICE_URL = "/app/ws/exchange/-1";
		var cancelExchangeUrl = EXCHANGE_SERVICE_URL + "/cancelExchange" + "?removeAllItems=" + p_cancelExchange;
		var svr =
		{
			url : cancelExchangeUrl,
			type : "POST"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result)
		{
			et.handleException(p_result);
			deferred.resolve(p_result.value);
		}).fail(function(p_result)
		{
			et.handleFailure(p_result);
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Returns seat block string built from postSaleSaleVo
	 */
	et.evenue.getPostSaleSeatDisplay = function(p_postSaleSeat)
	{
		var seatStr = p_postSaleSeat.levelSecDispValue;
		if (p_postSaleSeat.rowCd)
			seatStr += ", " + i18n.lbl_row + " " + p_postSaleSeat.rowCd;
		if (p_postSaleSeat.seatCd)
			seatStr += ", " + getSeatBlock(p_postSaleSeat.seatCd);
		return seatStr;
	};

	/*
	 * Returns seat block string built from postSaleSaleVo
	 */
	et.evenue.getPostSaleSeatBlkDisplay = function(p_postSaleSeat)
	{
		var seatStr = p_postSaleSeat.levelSecDispValue;
		if (p_postSaleSeat.seatingType == et.evenue.constants.GA)
			seatStr = i18n.lbl_gen_adm;
		else if (p_postSaleSeat.seatingType == et.evenue.constants.SECGA)
			seatStr += ": " + i18n.lbl_gen_adm;
		else
		{
			if (p_postSaleSeat.rowCd)
				seatStr += ", " + i18n.lbl_row + " " + p_postSaleSeat.rowCd;
			if (p_postSaleSeat.seatCd)
				seatStr += ", " + getSeatBlock(p_postSaleSeat.seatCd);
		}
		return seatStr;
	};

	var getSeatBlock = function(p_seatCd)
	{
		if (p_seatCd.indexOf(",") > -1)
		{
			var seats = p_seatCd.split(",");
			if (seats[0] == seats[1])
			{
				return i18n.lbl_seat + " " + seats[0];
			}
			else
			{
				return i18n.col_seats + " " + seats[0] + "-" + seats[1];
			};
		}
		else
		{
			return i18n.lbl_seat + " " + p_seatCd;
		}
	};

	/*
	 * Returns element
	 * p_type - type of element
	 * p_id	  - id of element
	 */
	var getSideElement = function(p_type, p_id)
	{
		var element;
		switch(p_type)
		{
			case "img" :
				element = $('<i></i>').addClass('fa fa-chevron-right');
				break;
			case "checkbox" :
				element = $('<input/>').prop("type", p_type).prop("id", p_id);
				break;
		}
		return element;
	};

	var getDiv = function()
	{
		return $("<div></div>");
	};

	var getImg = function()
	{
		return $("<img></img>");
	};

	var getLi = function()
	{
		return $("<li></li>");
	};

	var getLabel = function()
	{
		return $("<label></label>");
	};

	/*
	 * Format long
	 */
	et.evenue.getFormattedNumber = function(p_value, p_showCurrencySign)
	{
		return et.localeCurrencyDefault(p_value, et.locale, p_showCurrencySign);
	};

	/*
	 * Appends a seat blocks to an existing seat block string
	 * p_seatStr - element that new seats will be appended to
	 * p_seats - array of seatOHVos
	 */
	et.evenue.getSeatStr = function(p_seatStr, p_seats)
	{
		var seatStr = p_seatStr ? p_seatStr += "<br>" : "";
		$.each(p_seats, function(index, seat)
		{
			index > 0 ? seatStr += "<br>" : null;
			seatStr += et.evenue.getSeatBlock(seat);
		});
		return seatStr;
	};

	/*
	 * Appends transfer data to element
	 * p_element - element that data will be appended to
	 * p_content - lineItemOHVo
	 * p_displaySeason - true or false to display Season row
	 */

	et.evenue.getTransferData = function(p_element, p_lineItem, p_displaySeason)
	{
		var headers = [i18n.col_transfer_date, i18n.col_transfer_recipient, i18n.col_transfer_fee, i18n.lbl_season];
		var formattedContent = formatTransferData(p_lineItem, p_displaySeason);
		$.each(formattedContent, function(index, content)
		{
			p_element.append(getTransferRow(headers[index], content));
		});
	};

	var formatTransferData = function(p_lineItem, p_displaySeason)
	{
		var paidBy = "";
		if (p_lineItem[TRANSFER_PAID_BY] == "N")
			paidBy = i18n.ex_none;
		else
			paidBy = p_lineItem[TRANSFER_PAID_BY] == "T" ? i18n.lbl_paid_by_me : i18n.lbl_paid_by_recipient;

		var result = [et.createDateTime(p_lineItem.transferDtStr, et.datetimeOptions.SHORT, et.locale), paidBy];
		if (p_displaySeason)
			result.push(p_lineItem.season);
		return result;
	};

	/*
	 * Returns row element
	 * p_title - heading
	 * p_content - content
	 */
	var getTransferRow = function(p_title, p_content)
	{
		var tr = $("<tr>");
		tr.append($("<th>" + p_title + "</th><td>" + p_content + "</td>"));
		return tr;
	};

	/*
	 * Appends transfer data to element
	 * p_element - element that data will be appended to
	 * p_content - lineItemOHVo
	 * p_displaySeason - true or false to display Season row
	 */

	et.evenue.getPackageData = function(p_element, p_lineItem)
	{
		var headers = [i18n.col_package, i18n.lbl_order_date, i18n.col_package_cost, i18n.lbl_season];
		var formattedContent = formatPackageData(p_lineItem);
		$.each(formattedContent, function(index, content)
		{
			p_element.append(getTransferRow(headers[index], content));
		});
	};

	var formatPackageData = function(p_lineItem)
	{
		var result = [p_lineItem.name, et.createDateTime(p_lineItem.orderDtStr, et.datetimeOptions.SHORT, et.locale), et.evenue.getFormattedNumber(p_lineItem.totalPrice, true), p_lineItem.season];
		return result;
	};

	/*
	 * Returns select element with options
	 * p_selectOptions - list of options [{ id: "id", text: "Display Text" }]
	 */
	var getSelect = function(p_selectOptions)
	{

		var select = $('<select style="width: 145px;" class="form-control"></select>');
		if (p_selectOptions)
		{
			$.each(p_selectOptions, function(index, selectOption)
			{
				select.append($("<option />").val(selectOption.id).text(selectOption.text));
			});
		}
		return select;
	};

	et.evenue.forwardToErrorPage = function(p_presenter, p_params)
	{
		var params = p_params ? p_params :
		{
			errorCd : et.evenue.errorCodes.PAGE_NOT_AVAILABLE
		};
		p_presenter.pageDone(
		{
			forward : C.ERROR_PAGE,
			params : params
		});
	};

	et.evenue.cloneTemplateElement = function(p_templateId)
	{
		var startsWith = p_templateId.replace('template', '');
		var selectorStr = "[id^='" + startsWith + "']:not([id='" + p_templateId + "']):last";
		var currentElem = $(selectorStr);
		if (!currentElem.length)
		{
			currentElem = $("#" + p_templateId);
		}
		if (currentElem.length)
		{
			var id = +currentElem[0].id.match(/\d+/g) + 1;
			var newElem = $("#" + p_templateId).clone();
			newElem.attr('id', startsWith + id);
			return newElem;
		}
		return null;
	};

	et.evenue.getLastFour = function(p_value)
	{
		var result = '';
		if (p_value)
		{
			if (p_value.length >= 4)
			{
				result = p_value.substr(p_value.length - 4);
			}
			else
			{
				result = p_value;
			}
		}
		return result;
	};
	/*
	 * Method returns map of input fields and values
	 * p_formInputs - form inputs
	 * return inputValues - map
	 */
	et.evenue.getFormValues = function(p_formInputs)
	{
		var inputValues =
		{
		};
		p_formInputs.each(function()
		{
			if (this.type == 'checkbox')
			{
				inputValues[this.name] = $(this).is(":checked") ? true : false;
			}
			else
			{
				inputValues[this.name] = $(this).val();
			}
		});
		return inputValues;
	};

	/*
	 * Determines if p_page can be accessed
	 * Returns true/false
	 */
	et.evenue.isPageAllowed = function(p_page)
	{
		var deferred = $.Deferred();
		et.evenue.isAllowed(p_page).done(function(result)
		{
			if (result)
			{
				deferred.resolve(result.pageAllowed);
			}
			else
			{
				deferred.reject();
			}
		}).fail(function()
		{
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Determines if p_page can be accessed
	 * Returns json
	 * {
	 * 		pageAllowed	: true/false,
	 * 		userContext	: userContextVo,
	 * 		patron		: patronVo;
	 * }
	 */
	et.evenue.isAllowed = function(p_page)
	{
		var deferred = $.Deferred();
		var result =
		{
		};
		$.when(et.evenue.getUserContext(), et.evenue.getPatron()).done(function(userContext, p_patron)
		{
			result.userContext = userContext;
			result.patron = p_patron;
			var isPageAllowed = false;
			//StoredCreditCards, StoredBankAccounts, StoreCreditCard, StoredBankAccount
			if (p_page == "STORED_VALUES")
			{
				isPageAllowed = userContext.storedccbafl == 1 ? true : false;
			}
			//Donation History
			else
			if (p_page == "DONATION_HISTORY")
			{
				isPageAllowed = userContext.allowDonHistoryFlg == 1 ? true : false;
			}
			//Donation Details
			else
			if (p_page == "DONATION_DETAILS")
			{
				isPageAllowed = (userContext.allowDonHistoryFlg == 1 && userContext.dispDonDtlFlg == 1) ? true : false;
			}
			else
			if (p_page == et.evenue.constants.TRANSFER)
			{
				isPageAllowed = p_patron.transferfl == 1 ? true : false;
			}
			else
			if (p_page == et.evenue.constants.CANCEL_TRANSFER)
			{
				isPageAllowed = true;
			}
			else
			if (p_page == et.evenue.constants.RETURN)
			{
				isPageAllowed = p_patron.returnfl == 1 ? true : false;
			}
			else
			if (p_page == et.evenue.constants.EXCHANGE)
			{
				isPageAllowed = p_patron.exchfl == 1 ? true : false;
			}
			else
			if (p_page == "ORDER_HISTORY")
			{
				isPageAllowed = userContext.orderhistfl;
			}
			else
			if (p_page == et.evenue.constants.REISSUE)
			{
				isPageAllowed = userContext.reifl == 1 ? true : false;
			}
			else
			if (p_page == et.evenue.constants.PRIORITY_PTS)
			{
				isPageAllowed = userContext.custprifl == et.evenue.constants.YES || userContext.orderprifl == et.evenue.constants.YES || userContext.donprifl == et.evenue.constants.YES;
			}
			result.pageAllowed = isPageAllowed;
			//Add code to check if your page is allowed
			deferred.resolve(result);
		}).fail(function()
		{
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Determines if display is allowed for TICKET_FEE and FACILITY_FEE
	 */
	et.evenue.isPricingDisplayAllowed = function(p_type)
	{
		var result = false;
		$.when(et.evenue.getUserContext()).done(function(p_userContext)
		{
			var pricingDisplay = p_userContext.pricingDisplay;
			if (pricingDisplay == "3" && p_type == "FACILITY_FEE")//show facility only if displayMode = 3
			{
				result = true;
			}
			else
			if (pricingDisplay != "4" && p_type == "TICKET_FEE")//show ticket fee if display mode !=4 (i.e 1,2,3)
			{
				result = true;
			}
		});
		return result;
	};

	/*
	 * Method to create/display the ModalDialog region
	 * et.evenue.regions.ModalDialog.name must be included in required attribute of the presenter
	 * <div id="modal-dialog-region"><!-- Modal dialog --></div> must be in the pages html
	 * options
	 * {
	 * 		title			: Dialog title (Optional)
	 * 		content			: Context displayed in dialog box. (text or html) (Required)
	 * 		updateContent	: Updates the content of the dialog (Optional)
	 * 		callback		: Function executed when dialog box closes (Optional)
	 * 		timeout         : Value is a number in milliseconds. If the value is present,
	 * 						: the close button will be removed and the dialog box with close
	 * 						: automatically in the specified number of milliseconds
	 * }
	 */
	et.evenue.displayModalDialog = function(p_options)
	{
		var modalDialog = et.provide(et.evenue.regions.ModalDialog.name);
		if (modalDialog.isCreated())
		{
			modalDialog.updateContent(p_options);
		}
		else
		{
			modalDialog.go($(MODAL_DLG_REGION), function()
			{
				modalDialog.contextChanged(p_options);
			});
		}
		return false;
	};

	/*
	 * Build and return an array of objects grouped by season code
	 * Each item in the RETURN array has the seasonCd, array of p_lineItems for that seasonCd
	 * p_lineItems - array of any object that has field "seasonCd"
	 * TODO This could be made generic by being able to group by any attribute for example driveCd in donations
	 * Currently this method used by all order history pages
	 */
	et.evenue.groupObjectsBySeason = function(p_lineItems)
	{
		var objects;
		var object;

		if (p_lineItems)
		{
			objects = new Array();
			for (var i = 0; i < p_lineItems.length; i++)
			{
				var lineItem = p_lineItems[i];

				//if season exists already use it
				object = et.getValueFromListByField(objects, "seasonCd", lineItem.seasonCd);

				if (!object)
				{
					object =
					{
						seasonCd : lineItem.seasonCd,
						lineItems : new Array()
					};
					objects.push(object);
				}
				object.lineItems.push(lineItem);
			}
		}
		return objects;
	};

	/**
	 *called by UI framework when page changed.
	 */
	et.evenue.pageChanged = function(p_pageId, p_controller)
	{

		var defer = $.Deferred();

		/** check site/linkId/locale see if the page needs to be reloaded */
		//parse the default params
		var propSiteId = C.SITEID;
		var propLocale = C.LOCALE;
		var propLinkId = C.LINKID;
		var siteId = et.getPageParameter(propSiteId) || et.siteId;
		var locale = et.getPageParameter(propLocale) || et.locale;
		var linkId = et.getPageParameter(propLinkId) || et.evLinkId;
		//read the previous values from cookie
		var prvSiteId = $.cookie(et.cookiePrefix + propSiteId);
		var prvLinkId = $.cookie(et.cookiePrefix + propLinkId);
		var prvLocale = $.cookie(et.cookiePrefix + propLocale);
		//user switched site or locale, force reload.
		if ((prvLinkId != linkId) || (prvSiteId != siteId) || (prvLocale != locale))
		{
			setTimeout(function()
			{
				location.reload();
			}, 0);
			return defer.promise();
		}

		et.successUrl = et.DefaultPageParams[C.SUCCESSURL] = et.getPageParameter(C.SUCCESSURL);
		et.DefaultPageParams[C.BOOKMARK] = et.getPageParameter(C.BOOKMARK);

		var pageObj = p_controller.pages[p_pageId];
		if (pageObj.siteIdRequired === false)
		{
			//siteId is not required. For instance error page.
			defer.resolve();
		}
		else
		{
			//site id is required
			if (!et.siteId)
			{
				p_controller.navigate(C.ERROR_PAGE,
				{
					errorCd : et.evenue.errorCodes.SITE_ID_REQUIRED
				});
				defer.reject();
			}
			else
			{
				//has site id so let's validate it
				//resolve sellerId/poolId
				var deferSid = et.resolveSiteId(et.siteId);
				deferSid.done(function(p_siteId, p_sellerId, p_poolId)
				{
					et.sellerId = p_sellerId;
					et.poolId = p_poolId;
					defer.resolve();
				});
				deferSid.fail(function()
				{
					p_controller.navigate(C.ERROR_PAGE,
					{
						errorCd : et.evenue.errorCodes.SITE_ID_INVALID
					});
					defer.reject();
				});
			}
		}

		return defer.promise();
		;

	};

	/*
	 * Clear cart and redirect to evenue
	 */
	et.evenue.ClearCart = function(p_patronId, p_cartId)
	{
		// Clear cart web service
		var svr =
		{
			url : EC.PAYMENT_SERVICE_URL + p_patronId + '/cart',
			type : "GET"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
				var pageParams =
				{
					"cartNum" : p_cartId,
					"remove" : "all",
					"after" : "stay",
				"url" : window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/SECartRedisplay"
				};
				var redirectURL = "/cgi-bin/ncommerce3/SEUpdateCart?linkID=" + et.evLinkId;
			window.location = window.location.protocol + "//" + document.domain + et.createUrl(redirectURL, pageParams);
		}).fail(function()
		{
			et.handleFailure();
		});
		return;
	};
	
	// Remove the timer. Called from cancel and place order
	et.evenue.removeTimer = function() {
		clearInterval(m_eVenueTimerId);
        $("#cart-timer").hide(); 
	};
	
	/*
	 * Cancel Microservice cart 
	 */				
	et.evenue.cancelMicroserviceCart = function(p_ballenaCartId, p_orderId) {
        var deferred = $.Deferred();
		var svr =
		{
			url : et.evenue.constants.BALLENA_CANCEL_CART_URL+"?cartId="+ p_ballenaCartId + "&orderId=" + p_orderId,
			type : "POST"
		};
		var jqxhr = et.ajax(svr);
        jqxhr.done(function(data) {
            if (data.status.cd == 0) {
                deferred.resolve(data.value);
                et.evenue.removeTimer();
            } else {
                errors = [data.status.mg];
                et.displayMessagePanel({
                    msgs : errors,
                    element : $("#warning-panel"),
                    type : "W"
                });
                deferred.resolve(null);
            }

        }).fail(function() {
            et.handleFailure();
            deferred.resolve(null);
        });
	    return deferred.promise();
    };

	et.evenue.getMyAccountRedirectLink = function(){
		return window.location.protocol + "//" + window.location.host + window.location.pathname +
			"#MyAccount?" + "&siteId="+ et.siteId + "&linkID=" + et.evLinkId + "&locale=" +et.locale
	}
	
	et.evenue.getRxCartLink = function(){
		return window.location.protocol + "//" + window.location.host + "/evrx/cart/" + "?siteId="+ et.siteId + "&locale=" +et.locale
	}

    et.evenue.ClearMicroserviceCartTimer = function(p_cartId, p_orderId)
	{																																													
		$.when(et.evenue.cancelMicroserviceCart(p_cartId, p_orderId)).done(function(data){	
			if(p_orderId) {
				window.location = et.evenue.getRxCartLink();
			} else {
				//Set wbt=1 as a flkag to show "warning for ballena timer" on my account page
				window.location = et.evenue.getMyAccountRedirectLink() + "&wbt=1";
			}
		})
	};

	/*
	 * Clears the cart and send request to eVenue to clear its cart
	 */
	et.evenue.clearCart = function(p_patronId)
	{
		var deferred = $.Deferred();
		et.evenue.getCart().done(function(p_cart)
		{
			var svr =
			{
				url : EC.PAYMENT_SERVICE_URL + p_patronId + '/cart',
				type : "GET"
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				if (data.status.cd == 0)
				{
					et.evenue.clearEvenueCart(data);

				}
				else
				{
					deferred.reject();
				}

			}).fail(function()
			{
				deferred.reject();
			});
			return deferred.promise();
		});
	};

	/*
	 * Returns the cart
	 */
	et.evenue.getCart = function()
	{
		var deferred = $.Deferred();
		var svr =
		{
			url : EC.CART_URL,
			type : "GET"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data.value)
			{
				if (data.value.cartNumber > 0)
				{
					deferred.resolve(data.value);
				}
				else
				{
					deferred.reject();
				}
			}
			else
			{
				deferred.reject();
			}
		}).fail(function()
		{
			deferred.reject();
		});
		return deferred.promise();
	};
	
	et.evenue.getConfirmation = function(patronId) {
        var deferred = $.Deferred();
        var svr = {
            url : et.evenue.constants.PAYMENT_SERVICE_URL + patronId + '/confirmation',
            type : "GET"
        };
        var jqxhr = et.ajax(svr);
        jqxhr.done(function(data) {
            deferred.resolve(data.value);
        }).fail(function() {
            et.handleFailure();
        });
        return deferred.promise();
    };	

	et.evenue.clearEvenueCart = function(p_cart)
	{
		var deferred = $.Deferred();
		var params =
		{
			"cartNum" : p_cart.cartNumber,
			"remove" : "all",
			"after" : "stay",
			"url" : "https://" + document.domain + "/www/" + et.siteId + "/ss/evenue/success.txt"
		};
		var svr =
		{
			url : "/cgi-bin/ncommerce3/SEUpdateCart?linkID=" + et.evLinkId,
			data : params,
			type : "GET"
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (jqxhr.status == 200)
			{
				deferred.resolve(0);
			}
			else
			{
				deferred.reject();
			}
		});
		//Remove timer
		$("#cart-timer").hide();
		//Clear payment cache
		et.cache.payment = null;
		return deferred.promise();
	};

	/*
	 * Get current cart and start timer based on evenue time
	 */
	et.evenue.CartTimer = function()
	{
		var timerDiv = $("#cart_text");
		var p_count = 0;
		var p_cartId = 0;
		var m_patronId = -1;
		
		var microserviceCartId = null;
		var orderId = null;

		//Private function to start the cart timer and keep track of the remaining time
		var startTimer = function()
		{
			p_count = p_count - 1000;

			var minutes = Math.floor(p_count / 60000);
			var seconds = Math.floor((p_count % 60000) / 1000);
			if (minutes < 10)
			{
				minutes = "0" + minutes;
			}
			if (seconds < 10)
			{
				seconds = "0" + seconds;
			}
			if (p_count === 120000)
			{
				//TODO: Add alert for near timout
			}
			timerDiv.text(i18n.ibl_cart_timer_text_start + " " + minutes + ":" + seconds + " " + i18n.ibl_cart_timer_text_end);
			if (p_count <= 1000)
			{
				clearInterval(m_eVenueTimerId);
				//Clear cart and timer countdown
				if(microserviceCartId){
					et.evenue.ClearMicroserviceCartTimer(microserviceCartId, orderId);
				}
				else{
					et.evenue.ClearCart(m_patronId, p_cartId);
				}
				
				// return;
			}

		};

		//Get cart id and remaining time
		var svr =
		{
			url : EC.CART_URL,
			type : "GET"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data.value)
			{
				var ballenaCart = false;
				if(data.value.microserviceCartId && data.value.microserviceCartType != et.evenue.constants.CART_REQUEST_TYPE_RENEWAL && data.value.microserviceCartType != et.evenue.constants.CART_REQUEST_TYPE_RESERVE) {
					ballenaCart = true;
				}
				
				if (data.value.cartNumber > 0 || data.value.microserviceCartId && data.value.microserviceCartType != et.evenue.constants.CART_REQUEST_TYPE_RENEWAL)
				{
					microserviceCartId = data.value.microserviceCartId;
					orderId = data.value.orderId;
					if (data.value.remainingTime > 0)
					{

						p_count = data.value.remainingTime;
						p_cartId = data.value.cartNumber;
						$("#cart-timer").show();
						if(typeof m_eVenueTimerId != 'undefined')
						{
							clearInterval(m_eVenueTimerId);
						}
						m_eVenueTimerId = setInterval(startTimer, 1000);
						var continueUrl = "https://" + document.domain + "/cgi-bin/ncommerce3/SECartRedisplay?linkID=" + et.evLinkId + "&cartNum=" + p_cartId;
						if(microserviceCartId && orderId) {
							continueUrl = et.evenue.getRxCartLink();
						}

						if (microserviceCartId && ballenaCart) {
							$("#cart_icon").removeAttr('href').addClass('nopointer')
						} else {
							$("#cart_icon").attr("href", continueUrl);
						}
						
						return m_eVenueTimerId;
					}
					else
					{
						if(microserviceCartId){
							et.evenue.ClearMicroserviceCartTimer(microserviceCartId, orderId);
						}
						else{						
							et.evenue.ClearCart(m_patronId, data.value.cartNumber);	
						}
						
					}
				}
			}
		}).fail(function(data)
		{
			et.handleFailure(data);
		});

	};

	et.evenue.populateYearSelect = function(p_select)
	{
		var date = new Date();
		var year = date.getFullYear();
		for (var i = 0; i <= 10; i++)
		{
			p_select.append($("<option />").val(year + i).text(year + i));
		}
	};
	et.evenue.getCartTypeByAction = function(p_params)
	{
		var cartType;
		if (p_params.action !== "CHA" && p_params.action !== "AA")
		{
			cartType = p_params.cartType;
		}
		return cartType;
	};

	/*
	 * Returns a ContactInfoVo using form inputs
	 * p_formValues - input values
	 */
	et.evenue.getContactInfoVo = function(p_formValues)
	{
		contactInfoVo =
		{
			firstName : p_formValues["first-name"],
			lastName : p_formValues["last-name"],
			email : p_formValues.email,
			phone : p_formValues["day-phone"]
		};
		return contactInfoVo;
	};

	//**Get Quick Donation Details

	et.evenue.getQuickDonation = function(p_patronId, p_DonationCd)
	{
		var deferred = $.Deferred();
		var svr =
		{
			url : PATRON_SERVICES_URL + p_patronId + EC.QUICK_DONATION_URL + "?donationCd=" + p_DonationCd
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{

			deferred.resolve(data);


		}).fail(function()
		{
			et.handleFailure();
		});

		return deferred.promise();
	};

	et.evenue.getQuickDonationDetails = function(p_patronId)
	{
		var deferred = $.Deferred();
		var svr =
		{
			url : PATRON_SERVICES_URL + p_patronId + EC.QUICK_DONATION_DETAILS_URL
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data)
			{
				deferred.resolve(data);
			}
			else
			{
				et.handleException(data);
				deferred.reject();

			}

		}).fail(function()
		{
			et.handleFailure();
		});

		return deferred.promise();
	};

	et.evenue.setQuickDonationDetails = function(p_patronId, p_data)
	{
		var deferred = $.Deferred();
		var svr =
		{
			url : PATRON_SERVICES_URL + p_patronId + EC.QUICK_DONATION_DETAILS_URL,
			type : HTTP_POST,
			data : p_data
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data.value)
			{
				deferred.resolve(data);
			}
			else
			{
				et.handleException(data);
				deferred.reject();

			}

		}).fail(function()
		{
			et.handleFailure();
		});

		return deferred.promise();
	};

	et.evenue.submitQuickDonation = function(p_patronId, p_data)
	{
		et.showWaitPage();
		var deferred = $.Deferred();
		var svr =
		{
			url : PATRON_SERVICES_URL + p_patronId + EC.QUICK_DONATION_POST_URL,
			type : HTTP_POST,
			data : p_data
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data.value)
			{
				deferred.resolve(data);
			}
			else
			{
				et.dismissWaitPage();
				et.handleException(data);
				deferred.reject();
				;
			}

		}).fail(function()
		{
			et.handleFailure();
		});

		return deferred.promise();
	};

	/** display the waiting page with specified title and message*/
	et.showWaitPage = function(p_title, p_message)
	{
		var docHeight = $(document).height();
		var docWidth = $(document).width();

		var waitDiv;
		if (et._waitDiv)
		{
			waitDiv = et._waitDiv;
			waitDiv.find("#wait_title").html(p_title);
			waitDiv.find("#wait_msg").html(p_message);
		}
		else
		{
			waitDiv = $("<div>").load("global/view/wait.html", function()
			{
				waitDiv.find("#wait_title").html(p_title);
				waitDiv.find("#wait_msg").html(p_message);
			});
		}

		$("body").append(waitDiv);
		$('html, body').css(
		{
			'overflow' : 'hidden',
			'height' : '100%'
		});
		et._waitDiv = waitDiv;
	};

	/** dismiss the waiting page*/
	et.dismissWaitPage = function()
	{
		if (et._waitDiv)
			et._waitDiv.remove();
		$('html, body').css(
		{
			'overflow' : 'auto',
			'height' : 'auto'
		});
	};

	/*
	 * Returns OrderPaymentVo using form values
	 */
	et.evenue.getOrderPaymentFromForm = function(p_formValues, p_paymode)
	{
		var orderPaymentVo;
		if (p_formValues)
		{
			orderPaymentVo =
			{
				id : -1,
				storedValueId : 0,
				paymodeCd : p_formValues["card-type"],
				ccType : p_paymode ? p_paymode.ccType : "",
				paymodeType : et.evenue.constants.CREDITCARD,
				ccName : p_formValues["card-name"],
				paymentNmbr : p_formValues["card-number"],
				ccMonth : p_formValues["month-select"],
				ccYear : p_formValues["year-select"],
				nickName : p_formValues["card-nick-name"],
				storedFl : p_formValues["chk-store-card"] ? et.evenue.constants.YES : et.evenue.constants.NO,
				preferredFl : p_formValues["preferred-card"] ? et.evenue.constants.YES : et.evenue.constants.NO
			};
		}
		return orderPaymentVo;
	};

	/*
	 * Returns OrderPaymentVo using PtrnCreditCardVo
	 */
	et.evenue.getOrderPaymentFromStoredCard = function(p_storedCard)
	{
		var orderPaymentVo;
		if (p_storedCard)
		{
			orderPaymentVo =
			{
				paymentNmbr : p_storedCard.number,
				paymodeType : et.evenue.constants.CREDITCARD,
				paymodeCd : p_storedCard.paymodeCd,
				ccName : p_storedCard.nameOnCard,
				ccType : p_storedCard.type,
				ccYear : p_storedCard.expireYear,
				ccMonth : p_storedCard.expireMonth,
				nickName : p_storedCard.nickName,
				preferredFl : p_storedCard.preferredFl,
				storedValueId : p_storedCard.id,
				id : p_storedCard.id
			};
		}
		return orderPaymentVo;
	};

	et.evenue.isDecimal = function(e)
	{
		var regex = /^[0-9]+(\.[0-9]{1,2})?$/;
		e = e || window.event;
		var charCode = e.which ? e.which : e.keyCode;
		var inputChar = String.fromCharCode(charCode);
		inputChar = charCode == 46 ? inputChar + "0" : inputChar;
		var value = e.target.value + inputChar;
		return regex.test(value);
	};

	et.evenue.isValidEmailAddress = function(p_emailAddress)
	{
		var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
		return pattern.test(p_emailAddress);
	};

	et.evenue.getPostSaleOrderPayment = function(p_feeRegion, p_userContext, p_patronVo)
	{
		var orderPaymentVo;
		var selectedCard = p_feeRegion.getCardSelected();
		if (!selectedCard || selectedCard == 0)
		{
			var creditCardValues = p_feeRegion.formValues();
			var paymode = et.getValueFromListByField(p_userContext.paymodes, "paymodeCd", creditCardValues["card-type"]);
			orderPaymentVo = et.evenue.getOrderPaymentFromForm(creditCardValues, paymode);
		}
		else
		{
			var storedCard = et.getValueFromListByField(p_patronVo.storedCardVos, "id", selectedCard);
			orderPaymentVo = et.evenue.getOrderPaymentFromStoredCard(storedCard);
		}
		return orderPaymentVo;
	};

	/* common post sale returns methods*/

	et.evenue.returns =
	{
	};
	et.evenue.returns.writeRow = function(p_templateId, p_tablebody, p_seat, p_price, p_dc, p_hasCheckbox)
	{

		var row = et.evenue.cloneTemplateElement(p_templateId);
		var rs = $(".return-seat", row);
		rs.prop("for", p_seat.itemId).text(p_seat.content[0]);
		var rplpt = $(".return-plpt", row);
		rplpt.prop("for", p_seat.itemId).text(p_seat.content[1]);
		if (p_seat.content[2])
		{
			var rbc = $(".return-barcode", row);
			rbc.prop("for", p_seat.itemId).text(p_seat.content[2]);
		}
		else
		{
			$(".return-barcode", row).remove();
		}
		var rp = $(".return-price", row);
		rp.prop("for", p_seat.itemId).text(p_price);
		var rdc = $(".return-dc", row);
		rdc.prop("for", p_seat.itemId).text(p_dc);

		var cbElement = $('#return-cb', row);
		if (p_hasCheckbox)
		{
			cbElement.prop("id", p_seat.itemId);
		}
		else
		{
			cbElement.remove();
		}

		p_tablebody.append(row);
	};

	et.evenue.bindHeaderButton = function(p_element)
	{

		p_element.bind('click', function(e)
		{
			et.evenue.getUserContext().done(function(p_userContext)
			{
				if (!p_userContext.logon)
				{
					p_element.attr("href", "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + et.evLinkId + "&evm=myac&entry=main.html");
				}
				else
				{
					et.evenue.leaveExchange(e, p_element);
				}
			});
		});
	};

	et.evenue.leaveExchange = function(e, p_element, p_callBack)
	{
		e.preventDefault();
		et.evenue.exchangeCartAjax(null, p_callBack).done(function(p_result)
		{
			if (p_result === "1")
			{
				var modalDialog = $("#exch-release-seats-modal-dialog");
				var title = modalDialog.find(".modal-title");
				var body = modalDialog.find(".modal-body");

				title.text(i18n.lbl_warning);
				body.text(i18n.exchange_release_seats_msg);

				modalDialog.find("a").click(function(e1)
				{
					e1.preventDefault();
					et.evenue.cancelExchangeAjax(true).done(function()
					{
						modalDialog.modal("hide");
						if (p_callBack)
						{
							p_callBack();
						}
						else
						{
							window.location = et.createUrl(p_element.attr("href"));
						}

					});
				});

				modalDialog.find(".modal-footer>button").text(i18n.exchange_release_seats_cancel);

				modalDialog.modal("show");
			}
			else if (p_result === "2")
			{
				var EXCHANGE_SERVICE_URL = "/app/ws/exchange/-1";
				var clearExchangeUrl = EXCHANGE_SERVICE_URL + "/clearExchange";
				var svr =
				{
					url : clearExchangeUrl,
					type : "POST"
				};
				var jqxhr = et.ajax(svr);
				jqxhr.done(function(data)
				{
					et.handleException(data);
					if (p_callBack)
					{
						p_callBack();
					}
					else
					{
						window.location = et.createUrl(p_element.attr("href"));
					}
				}).fail(function(data)
				{
					et.handleFailure(data);

				});

			}

			else
			{
				if (p_callBack)
				{
					p_callBack();
				}
				window.location = et.createUrl(p_element.attr("href"));
			}
		});
	};

	et.evenue.notifyEvenueForPatronUpdates = function(p_patronId)
	{
		var deferred = $.Deferred();
		var params =
		{
			"linkID" : et.evLinkId,
			"account" : p_patronId,
			"url" : "https://" + document.domain + "/www/" + et.siteId + "/ss/evenue/success.txt"
		};
		var svr =
		{
			url : "/cgi-bin/ncommerce3/REGActivate",
			data : params,
			type : "GET"
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			deferred.resolve(true);
		}).fail(function()
		{
			deferred.resolve(false);
		});
		return deferred.promise();
	};

	et.evenue.formatEventDatetime = function(fromDate, toDate, hideDate, hideTime, isTimeTba)
	{
		var fromDateStr = et.createDateTime(fromDate, "FLD", et.locale, null, false), fromTimeStr = et.createDateTime(fromDate, "FLT", et.locale, null, false), toDateStr = et.createDateTime(toDate, "FLD", et.locale, null, false), toTimeStr = et.createDateTime(toDate, "FLT", et.locale, null, false), dateStr = "", timeStr = "";

		if (hideDate && hideTime)
		{
			// empty
			return "";
		}
		else
		if (hideDate)
		{
			// time only
			if (isTimeTba)
			{
				timeStr = i18n.lbl_dt_time + " " + i18n.lbl_dt_tba;
			}
			else
			if (fromDate && toDate)
			{
				timeStr = fromTimeStr + " " + i18n.lbl_dt_to + " " + toTimeStr;
			}
			else
			if (fromDate && !toDate)
			{
				timeStr = fromTimeStr;
			}
		}
		else
		if (hideTime)
		{
			// date only
			if (fromDate && toDate)
			{
				dateStr = fromDateStr + " " + i18n.lbl_dt_to + " " + toDateStr;
			}
			else
			if (fromDate && !toDate)
			{
				dateStr = fromDateStr;
			}
		}
		else
		{
			// date and time
			if (fromDate && !toDate)
			{
				// no end date and time
				dateStr = fromDateStr;
			}
			else
			if (fromDate && toDate)
			{
				// has both from and to date strs
				dateStr = fromDateStr + " " + i18n.lbl_dt_to + " " + toDateStr;
			}

			if (isTimeTba && fromDate && toDate)
			{
				// multi date event
				timeStr += i18n.lbl_dt_time + " " + i18n.lbl_dt_tba;
			}
			else
			if (isTimeTba && fromDate && !toDate)
			{
				// single date event
				timeStr += i18n.lbl_dt_at + " " + i18n.lbl_dt_tba;
			}
			else
			if (fromDate && toDate)
			{
				timeStr += i18n.lbl_dt_from + " " + fromTimeStr + " " + i18n.lbl_dt_to + " " + toTimeStr;
			}
			else
			if (fromDate && !toDate)
			{
				timeStr += i18n.lbl_dt_at + " " + fromTimeStr;
			}
		}

		if (dateStr)
		{
			return dateStr + " " + timeStr;
		}
		else
		{
			return timeStr;
		}

	};
	et.evenue.promptClearCart = function(p_element, p_patronId, p_callBack)
	{
		var svr =
		{
			url : EC.CART_URL,
			type : "GET"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data.value)
			{
				if (data.value.cartNumber > 0)
				{
					var modalDialog = $("#exch-release-seats-modal-dialog");
					var title = modalDialog.find(".modal-title");
					var body = modalDialog.find(".modal-body");

					title.text(i18n.lbl_warning);
					body.text(i18n.exchange_release_seats_msg);
					modalDialog.find("a").click(function(e)
					{
						e.preventDefault();
						var svr =
						{
							url : EC.PAYMENT_SERVICE_URL + p_patronId + '/cart',
							type : "GET"
						};
						var jqxhr = et.ajax(svr);
						jqxhr.done(function(data)
						{
							if (data.status.cd == 0)
							{
								et.evenue.clearEvenueCart(data);
								modalDialog.modal("hide");
								if (p_callBack)
								{
									p_callBack();
								}
							}

						}).fail(function()
						{
							deferred.reject();
						});

					});
					modalDialog.find(".modal-footer>button").text(i18n.exchange_release_seats_cancel);
					modalDialog.modal("show");
				}
				else
				{
					if (p_callBack)
					{
						p_callBack();
					}
				}
			}
			else
			{
				if (p_callBack)
				{
					p_callBack();
				}
			}
		});

	};
	

	et.evenue.getQueryParamValue = function(url, name) {
		var a = $('<a>', {
			href : url
		})[0];
		var params = a.search.split("&");
		var paramValue = "";
		$.each(params, function(index, value) {
			value = value.toLowerCase();
			if (value.indexOf(name) != -1) {
				var nvp = value.split("=");
				if (nvp.length > 1) {
					paramValue = nvp[1];
				}
			}
		});
		return paramValue;
	}

	et.evenue.promptClearCartBallena = function(p_patronId, p_callBack) {
		var cartTimerElement = $("#cart-timer:visible");
		if (cartTimerElement.length > 0) {
			var modalDialog = $("#exch-release-seats-modal-dialog");
			var title = modalDialog.find(".modal-title");
			var body = modalDialog.find(".modal-body");

			title.text(i18n.lbl_warning);
			body.text(i18n.exchange_release_seats_msg);

			modalDialog.find("a").click(function(e) {
				e.preventDefault();
				var svr = {
					url : EC.PAYMENT_SERVICE_URL + p_patronId + '/cart',
					type : "GET"
				};
				var jqxhr = et.ajax(svr);
				jqxhr.done(function(data) {
					if (data.status.cd == 0) {
						et.evenue.clearEvenueCart(data).done(function() {
							modalDialog.modal("hide");
							p_callBack();
						});
					}

				}).fail(function() {
					deferred.reject();
				});
			});
			modalDialog.find(".modal-footer>button").text(
					i18n.exchange_release_seats_cancel);
			modalDialog.modal("show");
		} else {
			p_callBack();
		}
	};
	

	et.evenue.getUpgradeSitesTimeslots = function(queryParams) {
		var deferred = $.Deferred();
		var svr = {
			url : BALLENA_TIMESLOT_URL + queryParams
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			if (data.status.cd == 0) {
				deferred.resolve(data.value);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};
	
	et.evenue.generateRelatedAndUpgradeSites = function(accountdetails , m_userContext , BALLENA_SITE_NAME_PREFIX,QUERY_NAME_PID) {
	
		var ssoQueryString="";
		var relatedSites = [];
		var upgradeSites = [];
		ballenaPids = [];
	
		var myAccountURL = "" ; // link to my account encode and send this as a query parameter to ballena
		var paymentURL = "" ; // link to evnavigation encode and send this as a query parameter to ballena
	
		if (accountdetails.relatedSites
				&& accountdetails.relatedSites.length > 0) {
			for (var i = 0; i < accountdetails.relatedSites.length; i++) {
				var siteData = $.extend({}, accountdetails.relatedSites[i]);
				var siteStr = siteData.name.substr(0, 3).toUpperCase();
				if (siteStr == BALLENA_SITE_NAME_PREFIX) {
					// Ballena upgrade opportunity sites
					var pid = et.evenue.getQueryParamValue(siteData.value,
							QUERY_NAME_PID);
					if(pid) {
						ballenaPids.push(pid);
					}
														
					if (window.location.protocol && window.location.host
							&& window.location.pathname && window.location.hash) {
																																		
						myAccountURL = window.location.protocol + "//" + window.location.host + window.location.pathname + 
									"#MyAccount?action=CH&cartType=T&ballena="+ pid + "&siteId="+ et.siteId + "&linkID=" + et.evLinkId + "&locale=" +et.locale;									
						paymentURL =  window.location.protocol + "//" + window.location.host + window.location.pathname  
						+ "?p=1#EvNavigation?action=CH&cartType=T&ballena="+ pid + "&siteId="+ et.siteId + "&linkID=" + et.evLinkId + "&locale=" +et.locale;
					}
					ssoQueryString = "&cn=" + m_userContext.patronId + "&ts="
							+ m_userContext.timestamp + "&pw=" + m_userContext.hashcode;
					var upgradeQueryString = ssoQueryString + "&url_ma=" 
							+ encodeURIComponent(myAccountURL) + 
							"&url_pay=" + encodeURIComponent(paymentURL) ;
										
					siteData.pid = pid;
					siteData.name = siteData.name.substr(3);
					siteData.value += upgradeQueryString;
					upgradeSites.push(siteData);
				} else {
					// related sites
					relatedSites.push(siteData);
				}
			}
		}
		
		return {"ssoQueryString": ssoQueryString , "ballenaPids" : ballenaPids ,  "relatedSites" : relatedSites , "upgradeSites" : upgradeSites };		
	};

	/** Returns terms and conditions HTML for modal */
	et.evenue.termsAndConditions = function()
	{
		var svr =
		{
			url : "customize/" + et.siteId + "/terms/termsConditions.html",
			type : "GET"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			var options =
			{
				title : i18n.hdr_tc,
				updateContent : true,
				content : data
			};
			et.evenue.displayModalDialog(options);

		}).fail(function()
		{
			var options =
			{
				title : i18n.hdr_tc,
				updateContent : true,
				content : "Place holder"
			};
			et.evenue.displayModalDialog(options);
		});

	};

	et.evenue.services = {};

	et.evenue.services.pac8 = {
		//Get the pac8 user context
		getUserContext: function() {
			var deferred = $.Deferred();
			var svr = {
				url : '/app/rs/contexts?siteId=' + et.siteId,
				type : 'GET'
			};
			var request = et.ajax(svr);
			request.done(function(result) {
				deferred.resolve(result.values.userCtx);
			}).fail(function() {
				et.handleFailure();
				deferred.reject();
			});

			return deferred.promise();
		},
		getAccountData : function(id) {
			var deferred = $.Deferred();
			var account = et.cache.account;
			if (!account) {
				var svr = {
					url : "/app/rs/accounts/" + id
				};
				var jqxhr = et.ajax(svr);
				jqxhr.done(function(result) {
					et.cache.account = result.values.account;
					deferred.resolve(result.values.account);
				}).fail(function() {
					deferred.reject();
				});
			} else {
				deferred.resolve(account);
			}
			return deferred.promise();
		},
		getAccountPriorityPoints : function(accountId) {
			var deferred = $.Deferred();
			var svr = {
				url : "/app/rs/accounts/" + accountId + "/prioritypoints",
				type : et.constants.HTTP_GET
			};
			var request = et.ajax(svr);
			request.done(function(result) {
				deferred.resolve(result);
			}).fail(function() {
				et.handleFailure();
				deferred.reject();
			});

			return deferred.promise();
		},
		getPriorityPointsControlSettings : function() {
			var deferred = $.Deferred();
			var svr = {
				url : '/app/rs/priorityPointsConsumerDetails',
				type : 'GET'
			};
			var request = et.ajax(svr);
			request.done(function(result) {
				deferred.resolve(result);
			}).fail(function() {
				et.handleFailure();
				deferred.reject();
			});

			return deferred.promise();

		},
		getPriorityPointsPrograms : function(orgId) {
			var deferred = $.Deferred();
			var svr = {
				url : et.evenue.constants.WS_PREFIXES + et.evenue.constants.VERSIONED_WS + et.evenue.constants.FUNDRAISING_WS + '/' + orgId + '/priorityPointPrograms',
				type : 'GET'
			};
			var request = et.ajax(svr);
			request.done(function(result) {
				deferred.resolve(result);
			}).fail(function() {
				et.handleFailure();
				deferred.reject();
			});

			return deferred.promise();
		},
		getControlSetting : function(channelId, orgId, type) {
            var deferred = $.Deferred();
            var SERVICE_IAC_CONTROL_SETTINGS_URL = "/app/rs/controlsettings/{type}/{channelId}/{orgId}";
            var url = SERVICE_IAC_CONTROL_SETTINGS_URL.replace("{channelId}", channelId).replace("{orgId}", orgId).replace("{type}", type);
            var svr = {
                type : et.constants.HTTP_GET,
                url : url
            };
            var jqxhr = et.ajax(svr);
            jqxhr.done(function(result) {
                deferred.resolve(result);

                et.cache.solicitorInfo = result;
            }).fail(function(err) {
                et.handleFailure(err);
                deferred.reject();
            });

            return deferred.promise();
        },
        isViewPaymentScheduleEnabled : function(accountId) {
    		var deferred = $.Deferred();
    		var url = "/app/rs/paymentSchedules/" + accountId + "/check";

    		var svr = {
    			type : et.constants.HTTP_GET,
    			url : url
    		};
    		et.ajax(svr).done(function(result) {
    			deferred.resolve(result.values);
    		}).fail(function() {
    			deferred.reject();
    		});
    		return deferred.promise();
    	},
    	getZookeeperConstants: function(zkpConstant) {
        	var deferred = $.Deferred();
    		var svr = {
    			url : '/app/rs/common/zookeeper/' + zkpConstant,
    		};

    		var jqxhr = et.ajax(svr);
    		jqxhr.done(function(result) {
    			if (result && result.status && result.status.cd == 0) {
    				deferred.resolve(result.data);
    			} else {
    				deferred.resolve(null);
    			}
    		}).fail(function() {
    			deferred.resolve(null);
    		});
    		
    		return deferred.promise();
		},
		getMA2DesktopEnabled: function(siteId, linkID) {
			var deferred = $.Deferred();
			var svr = {
				url : 'https://my-account-config.s3-us-west-2.amazonaws.com/' + siteId + '_' + linkID + '.json',
			};

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(result) {
				if (result && result.default && result.default.newDesktopFlow) {
					deferred.resolve(true);
				} else {
					deferred.resolve(false);
				}
			}).fail(function() {
				deferred.resolve(null);
			});

			return deferred.promise();
		},
		getMA2Navigation: function() {
			var deferred = $.Deferred();
			var svr = {
				type : et.constants.HTTP_POST,
				data :
				{
					domain: "http://",
					siteId: et.siteId,
					linkId: et.evLinkId
				},
				url : '/pac-api/accounts/navigation',
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(result) {
				if (result && result.status && result.status.cd == 200) {
					deferred.resolve(result.result);
				} else {
					deferred.resolve(null);
				}
			}).fail(function() {
				deferred.resolve(null);
			});

			return deferred.promise();
		}
	};
	
	et.evenue.microservice = {};

 	 et.evenue.microservice.getCart = function(cartId, source) {
        var deferred = $.Deferred();
        
        var svr = {            
            url: et.evenue.constants.CART_SERVICE_URL + "?cartId=" + cartId
        };
        if(source) {
        	svr.url += "&source=" + source
        }
        var jqxhr = et.ajax(svr);
        jqxhr.done(function(data) {
        	if (data.status.cd == 0) {
            	deferred.resolve(data.value);
            }
            else{
            	deferred.resolve(data);
            }

        }).fail(function() {
            et.handleFailure();
        });
       
        return deferred.promise();
    };
    et.evenue.checkForJumpCookie = function()
	{
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
        	    var key = cookie.split("=");
        	    if(key[0] == " pool.jump.status" && key[1] == "jumped")
        	    {
        	    	    return true;
        	    } 
		}
        return false;
	};
	et.evenue.deleteAllCookies = function()
	{
		var svr =
		{
			url : EC.LOGOUT_URL,
			data :
			{
				"siteId" : et.evLinkId
			},
			type : HTTP_POST
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			et.evenue.deleteSessionCookies();
			var signInURL = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + et.evLinkId + "&evm=myac&entry=main.html";
			window.location = signInURL;
		}).fail(function()
		{
			 et.evenue.deleteSessionCookies();
			 var signInURL = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + et.evLinkId + "&evm=myac&entry=main.html";
			 window.location = signInURL;
		
		});
	
		
	};
	
	et.evenue.handleJumpPool = function(patronId) {
		var cartNumber = sessionStorage.getItem("cartNumber");
		if(cartNumber > 0)
		{ 
			var params =
			{
				"cartNum" : cartNumber,
				"remove" : "all",
				"after" : "stay",
				"url" : "https://" + document.domain + "/www/" + et.siteId + "/ss/evenue/success.txt"
			};
			var svr =
			{
				url : "/cgi-bin/ncommerce3/SEUpdateCart?linkID=" + et.evLinkId,
				data : params,
				type : "GET"
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data)
			{
				$("#cart-timer").hide();
				et.cache.payment = null; 
				et.evenue.deleteAllCookies();
				
			}).fail(function()
			{
				et.evenue.deleteAllCookies();
	
			});
		}
		else
		{
			et.evenue.deleteAllCookies();
		}
	};
	et.evenue.deleteSessionCookies = function() {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if( name == " SESSION_ID" 
            		|| name == " old_session_id" 
            		|| name == " active.pool"
            		|| name == " pool.jump.status")
            	{
            		document.cookie = name + "=;expires=-1;path=/";
            	}
        }
        $.removeCookie("SESSION_ID", { path: '/' });
        $.removeCookie("old_session_id", { path: '/' });
        $.removeCookie("active.pool", { path: '/' });
        $.removeCookie("pool.jump.status", { path: '/' });
    };

	var linkID = et.getPageParameter(et.constants.LINKID);
	var isDevEnviroment = /^(dev|qfnq|qfns|auto)$/g.test(sessionStorage.getItem("global_env"));
	var awsPath = isDevEnviroment
		? "https://pachtml-dev.s3-us-west-2.amazonaws.com/www/"
		: "https://s3-us-west-2.amazonaws.com/pachtml-production/www/";
	// DISC-1905 (refactor siteConfig call)
    var sessionStorageSiteConfig = sessionStorage.getItem('siteConfig_' + linkID);
	if (sessionStorageSiteConfig === null) {
		jQuery.ajax({
			url: awsPath + linkID + "/info/site.json",
			async: false,
			success: function(data) {
				if (data.linkID == linkID) {
					sessionStorage.setItem('siteConfig_' + linkID, JSON.stringify(data));
				}
				jQuery(document).trigger("triggerReceivedSiteJSON");
			}
		});
	}

	// init siteConfig
	et.siteConfig = JSON.parse(sessionStorage.getItem('siteConfig_' + linkID)) || {};

	// init features
	et.features = {};
	if (et.siteConfig.hasOwnProperty("features")) {
		et.features = et.siteConfig.features;
	}

	// init business name for title
	if (et.siteConfig.hasOwnProperty("businessName")) {
		et.businessName = et.siteConfig.businessName;
	}

	//DISC-130
    et.evenue.pac_divloaded = function (r_id, e_id) {
        var ev_deffered = $.Deferred();
        var mRegion = setInterval(function () {
            if ($.trim($("#" + r_id).html()) !== "") {
                if (e_id != undefined) {
                    if ($.trim($("#" + e_id).html()) !== "") {
                        clearInterval(mRegion);
                        ev_deffered.resolve();
                    }
                } else {
                    clearInterval(mRegion);
                    ev_deffered.resolve();
                }
            }
        }, 100);
        return ev_deffered.promise();
    };

	//DISC-519 - GTM module
	var loadExtLib = function (url, callback) {
		var script = document.createElement("script")
		script.type = "text/javascript";
	
		if (script.readyState){  //IE
			script.onreadystatechange = function(){
				if (script.readyState == "loaded" ||
						script.readyState == "complete"){
					script.onreadystatechange = null;
					callback();
				} 
			};
			
	
		} else {  //Others
			script.onload = function(){
				callback();
			};
			script.onerror = function() {
				console.log('FP LOADING ERROR');
				sendToLoggly('Evenue FP module loading error', {logType: "debug", file:'payment.js'});
			}
		}
	
		script.src = url;
		document.getElementsByTagName("head")[0].appendChild(script);
	}

    // DISC-1431 (init global media path. NOTE: isDevEnviroment declared above
    et.mediaPath = isDevEnviroment
        ? 'https://s3.us-west-2.amazonaws.com/media.qa.webdev/common/legacy/scripts'
        : 'https://pac-media.s3-us-west-2.amazonaws.com/common/legacy/scripts';

	var gtmModulePath = et.mediaPath + "/pixels/gtmDatalayerModule.js";
	// DISC-1905 (apply timestamp)
	loadExtLib(gtmModulePath + '?timestamp='+new Date().valueOf(), function () {});

	/**
	 * Function to wait for predicates.
	 * @param {function() : Boolean} predicate - A function that returns a bool
	 * @param {Number} [timeout] - Optional maximum waiting time in ms after rejected
	 */
	et.evenue.waitForGlobalVar = function(predicate, timeout) {
		return new Promise(function (resolve, reject)  {
			const check = function() {
			if (!predicate()) return;
			clearInterval(interval);
			resolve();
			};
			const interval = setInterval(check, 100);
			check();
		
			if (!timeout) return;
			setTimeout(function (){
			clearInterval(interval);
			reject();
			}, timeout);
		});
	};
	
	et.evenue.getValueForObjectString = function(obj, key) {
		return key.split(".").reduce(function(o, x) {
			return (typeof o == "undefined" || o === null) ? o : o[x];
		}, obj);
	};


	et.evenue.dataLayerLoadedPredicate = function() {
		return window['dataLayer'].filter(
			function(obj) {
				var found = false;
				var prop;
				
				for (prop in {"event": "data_layer_loaded"}) {
					if (obj[prop] == {"event": "data_layer_loaded"}[prop] || et.evenue.getValueForObjectString(obj, prop) == {"event": "data_layer_loaded"}[prop]) {
						found = true;
					}
				}
			return found;
		}).length > 0;
	};

	//TF-502 : send GA4 shibboleth login event if sessionStorage flag available
	if (window.sessionStorage.getItem('signedInViaShibboleth') !== null && window.sessionStorage.getItem('signedInViaShibboleth') === "true"){
		var urlPathFilter = window.location.hash;
		var pageNameGa4 = urlPathFilter.replace(/(#)(.*?)(\?.*)/, "$2");
		var gaObj = {
			event: "login",
			destination: pageNameGa4,
			method: "shibboleth",
			status : "success",
			process: "login"
		};

		var getIsStudent = function() {
			var deferred = $.Deferred(); 
			
			var xhr = new XMLHttpRequest();
			xhr.withCredentials = true;
			xhr.open("GET", "/pac-api/auth/authz");
			xhr.onload = function () {
				if (xhr.status === 200) {
					try {
						var response = JSON.parse(xhr.responseText);
						if (response.result) {
							deferred.resolve(response.result.student);
						} else {
							deferred.resolve(undefined);
						}
					} catch (e) {
						deferred.resolve(undefined);
					}
				} else {
					deferred.resolve(undefined);
				}
			};
			xhr.onerror = function() {  
				deferred.resolve(undefined);
			 };
			xhr.send();  
			
			return deferred.promise();
		};

		var pushToDataLayer = function(gaObj){
			window.sessionStorage.removeItem('signedInViaShibboleth');
			et.evenue.waitForGlobalVar(et.evenue.dataLayerLoadedPredicate, 4000).then(function () {
				dataLayer.push(gaObj);
			}).catch(function (e) {
				console.log("error async data_layer_loaded shibboleth login event - common.js"+ e);
				// eventhough data_layer_loaded didn't show up in 5sec  we will still send the event 
				dataLayer.push(gaObj);
			});
		}; 
		
		$.when(getIsStudent()).done(function(student) { 
			if (student !== undefined) {
				gaObj.is_student = student;
			}; 
			pushToDataLayer(gaObj);
		}).fail(function (e) {
			pushToDataLayer(gaObj)
		});
	}
    
})();
